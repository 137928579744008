import {makeAutoObservable, configure} from "mobx";
import {toJS} from "mobx";
import AuthService from "../services/AuthService";
import {API_URL} from "../http";
import $api from '../http';
import axios from 'axios';




configure({
    enforceActions: "never",

})


//создаем и сразу экспортируем класс, видимо это будет класс с которым работает mobx
//А mobx работает с состояниями,
export default class Store {
    user = {};
    mail = "";
    password = "";
    isAuth = false;
    choice = "unknown";
    isLoading = false;
    isTriedToSign = false;
    isTriedToRegister = false;
    isActivated = false;
    isUserDataAdded = false;
    errorMessage = "";
    notifyCounter = 0;
    //moderNotifyCounterIn = 0;
    //moderNotifyCounterOut = 0;
    moderNotifyCounter = [];
    moderStakanUpdater = [];
    moderStakanReducer =[];
    notificationsIn = {};
    notificationsOut = {};
    moderNotificationsIn = {};
    moderNotificationsOut = {};
    messagesAboutIncoming = [];
    messagesAboutOutgoing = [];
    hiddenUsers = {};
    requested = {};
    requests = [];
    update = 0;
    moderUpdate = 0;
    updateOrderProcess = 0;
    isAuthor = false;
    eventCounter = 0;
    eventUpdater = 0;
    feedUpdater = 0;
    removeFromFeed = "";
    returnToFeed= "";
    newPriceFor = "";
    feedBlinker = false;
    whitePointer = "";
    closingDialog = "";
    messageTo = "";
    privateUserList = [];
    fullUserList = [];
    privateUnreadCounter = 0;
    workUnreadCounterIn = 0;
    workUnreadCounterOut = 0;
    workUnreadCounterModer = 0;
    invocations = {};
    invocationUpdater = 0;
    balance = 0;
    paymentBlock = false;
    chatList = [];
    activeChat = {};
    totalBan = false;


    chatToOpenOnLink = ""

    chatId = ""
    pageId = ""
    messageCenterMode = ""





    constructor() {
        //функция вызывается для того чтобы mobx мог работать с данным классом
        makeAutoObservable(this)
        this.logout = this.logout.bind(this);
    }


    //ниже мутации, это просто функции сеттеры как в useState

    setMessageCenterMode(mode) {
        this.messageCenterMode = mode
    }


    setPageId(id) {
        this.pageId = id
    }

    setChatId(id) {
        this.chatId = id
    }

    setChatToOpenOnLink(id) {
        this.chatToOpenOnLink = id
    }

    setUserPhone(phone) {
        this.user.phone = phone
    }

    setInvocations(invocations) {
        this.invocations = invocations
        this.invocationUpdater++
    }

    deleteInvocationsByChatId(chat_id) {
        delete this.invocations[chat_id]
        this.invocationUpdater++
    }

    removeInvocation(id, key) {
        console.log(id)
        console.log(key)
        const filteredArray = this.invocations[key].filter(obj => obj._id !== id);

        if (filteredArray.length > 0) {
            this.invocations = {
                ...this.invocations,
                [key]: filteredArray
            };
        } else {
            const { [key]: removedKey, ...rest } = this.invocations;
            this.invocations = rest;
        }
        console.log(toJS(this.invocations))
        this.invocationUpdater++

    }

    addInvocation(invocation) {
        console.log("ADDING INVOCATION")
        if (this.invocations[invocation.chat_id]) {
            this.invocations[invocation.chat_id].push(invocation)
        } else {
            this.invocations[invocation.chat_id] = [invocation]
        }
        this.invocationUpdater++
    }


    setBalance(number) {
        this.balance = number
    }

    setPaymentBlock(state) {
        this.paymentBlock = state
    }

    setChatList(list) {
        this.chatList = list
    }


    setActiveChat(chat) {
        this.activeChat = chat
    }

    addChatToChatList(chat) {
        this.chatList.push(chat)
    }

    setTotalBan(state) {
        this.totalBan = state
    }

    setWorkUnreadCounterIn(number) {
        if(number >= 0) {
            this.workUnreadCounterIn = number
        } else {
            this.workUnreadCounterIn = 0
        }
    }


    setWorkUnreadCounterOut(number) {
        if(number >= 0) {
            this.workUnreadCounterOut = number
        } else {
            this.workUnreadCounterOut = 0
        }
    }

    setWorkUnreadCounterModer(number) {
        if(number >= 0) {
            this.workUnreadCounterModer = number
        } else {
            this.workUnreadCounterModer = 0
        }
    }


    setPrivateUnreadCounter(number) {
        if(number >= 0) {
            this.privateUnreadCounter = number
        } else {
            this.privateUnreadCounter = 0
        }
    }


    setPrivateUserList(list) {
        this.privateUserList = list
    }

    setFullUserList(list) {
        this.fullUserList = list
    }

    addToPrivateUserList(user) {
        this.privateUserList.unshift(user)
    }

    addToFullUserList(user) {
        const currentIdList = this.fullUserList.map(user => user.id)
        if(!currentIdList.includes(user.id.toString())) {
            this.fullUserList.unshift(user)
        }

    }

    removeFromFullUserList(user_id) {

        const newFullUserListState = this.fullUserList.filter(user => user.id !== user_id)
        this.fullUserList = newFullUserListState
        console.log(newFullUserListState)

    }

    removeFromPrivateList(user) {
        console.log("До удаления:")
        console.log(toJS(this.privateUserList))
        const newList = this.privateUserList.filter(item => item.id !== user)
        this.privateUserList = newList
        console.log("После удаления:")
        console.log(toJS(this.privateUserList))


        const newUserListStateInUser = this.user.privateUserList.filter(item => item.id !== user)

        this.user.privateUserList = newUserListStateInUser




    }

    setMessageTo(receiver) {
        this.messageTo = receiver
    }

    setClosingDialog(string) {
        this.closingDialog = string
    }

    setErrorMessage(message) {
        this.errorMessage = message
    }

    editWhitePointer(newNav) {
        this.whitePointer = newNav
    }

    setEventCounter(value) {
        console.log(`Event counter set to ${value}`)
        this.eventCounter = value
        //при установке счетчика событий, записываем его значение так же и в локальное хранилище браузер
        localStorage.setItem('eventCounterHeader', this.eventCounter.toString())
        this.eventUpdater++

    }

    decreaseEvent() {
        this.eventCounter--
        console.log('Event decreased')
        //при уменьшении счетчика событий, записываем его значение так же и в локальное хранилище браузер
        localStorage.setItem('eventCounterHeader', this.eventCounter.toString())
    }

    eventUpdate() {
        this.eventUpdater++
    }

    feedUpdate() {
        this.feedUpdater++
    }

    setNewPriceFor(order) {
        this.newPriceFor = order
    }

    setRemoveFromFeed(order){
        this.removeFromFeed = order
    }

    setReturnToFeed(order){
        this.returnToFeed = order
    }

    setFeedBlinker(state){
        this.feedBlinker = state
    }

    setIsAuthor(state) {
        this.isAuthor = state
    }

    setRequested(orders) {

        this.requested = orders
    }

    addRequested(order, user) {
        if(this.requested[order]) {
            this.requested[order].push(user)
        } else {
            this.requested[order] = [user]
        }
    }

    setRequests(orders) {

        this.requests = orders

    }


    addRequests(order) {

        this.requests.push(order)

    }


    removeRequests(order) {
        console.log('Trying to remove request by order ', order)
       for(let item in this.requests) {

           if(this.requests[item]._id === order) {
               const index = this.requests.indexOf(this.requests[item])
               this.requests.splice(index, 1)
               break
           }
       }
    }


    setUpdate() {
        this.update++
    }

    setModerUpdate() {
        this.moderUpdate++
    }

    setUpdateOrderProcess() {
        this.updateOrderProcess++
    }


    setStartValueForMessagesAboutIncoming(array) {
        this.messagesAboutIncoming = array
    }

    setStartValueForMessagesAboutOutgoing(array) {
        this.messagesAboutOutgoing = array

    }

    setStartValueForHiddenUsers(object) {
        this.hiddenUsers = object

    }

    addHiddenUser(order_id, user_id) {
        this.hiddenUsers[[order_id]].push(user_id)

    }


    removeHiddenUser(order_id, user_id) {
        const index = this.hiddenUsers[order_id].indexOf(user_id)
        this.hiddenUsers[order_id].splice(index, 1)
    }


    addMessagesAboutIncoming(order) {
        console.log(toJS(this.messagesAboutIncoming))
        this.messagesAboutIncoming.push(order)
        console.log(toJS(this.messagesAboutIncoming))
    }

    removeMessagesAboutIncoming(order) {

        this.messagesAboutIncoming = this.messagesAboutIncoming.filter(item => item !== order)

    }

    addMessagesAboutOutgoing(order) {
        this.messagesAboutOutgoing.push(order)
    }

    removeMessagesAboutOutgoing(order) {
        this.messagesAboutOutgoing = this.messagesAboutOutgoing.filter(item => item !== order)

    }


    setNotifyCounter(num) {
        this.notifyCounter = num;
    }


    setModerNotifyCounter(array) {
        this.moderNotifyCounter = array
    }

    addToModerNotifyCounter(order_id) {

        if(this.moderNotifyCounter.indexOf(order_id) === -1) {
            this.moderNotifyCounter.push(order_id)

        }

    }

    removeFromModerNotifyCounter(order_id) {
        const index = this.moderNotifyCounter.indexOf(order_id)
        if(index > -1) {
            this.moderNotifyCounter.splice(index, 1)
        }
    }



    moderStakanUpdate(order) {
        this.moderStakanUpdater = [order]
    }

    moderStakanReduce(order) {
        this.moderStakanReducer = [order]
    }

    // setModerNotifyCounterIn(num) {
    //     this.moderNotifyCounterIn = num;
    // }
    //
    // setModerNotifyCounterOut(num) {
    //     this.moderNotifyCounterOut = num;
    // }


    setNotificationsIn(array) {
        //console.log("HERE AND NOW")
        this.notificationsIn = array
    }

    setNotificationsOut(array) {
        this.notificationsOut = array
    }

    setModerNotificationsIn(array) {
        this.moderNotificationsIn = array
    }

    setModerNotificationsOut(array) {
        this.moderNotificationsOut = array
    }

    addNotificationsOut(notification) {
        console.log(notification)
        console.log(toJS(this.notificationsOut))

        if (notification.from !== this.user.id) {

            if (toJS(this.notificationsOut)[notification.about]) {
                //console.log("IF1")
                if (toJS(this.notificationsOut)[notification.about].indexOf(notification.from) !== -1) {
                    //console.log("IF2")
                    ;
                } else {
                    //console.log("ELSE2")
                    //this.notificationsOut = toJS(this.notificationsOut)[notification.about].push(notification.from)
                    let objectToChange = toJS(this.notificationsOut)
                    //console.log(notification.from)
                    objectToChange[notification.about].push(notification.from)
                    this.notificationsOut = objectToChange
                }
            } else {
                //console.log("ELSE1")
                this.notificationsOut[notification.about] = [notification.from]
            }
        }
    }


    modifyNotificationsIn(state) {
        console.log("MODIFYING IN")
        console.log(toJS(this.notificationsIn))
        this.notificationsIn = state
        console.log(toJS(this.notificationsIn))
    }


    removeNotificationsIn(orderId) {
        //console.log(toJS(this.notificationsIn))
        delete this.notificationsIn[orderId]
        //console.log(toJS(this.notificationsIn))
    }


    removeNotificationsOut(orderId, from) {
        //let newState = Object.assign(this.notificationsOut)

        const index = this.notificationsOut[orderId]?.indexOf(from)


        if(index !== -1 && index !== undefined) {

            this.notificationsOut[orderId].splice(index, 1)
            console.log("УМЕНБЬШАЕМ СЧЕТЧИК?")
            //this.notifyCounter--

            if(this.notificationsOut[orderId].length === 0) {
                console.log("DVA")
                delete this.notificationsOut[orderId]

            }
        }
    }


    modifyNotificationsOut(state) {
        console.log("MODIFYING OUT")
        console.log(state)
        console.log(toJS(this.notificationsOut))
        this.notificationsOut = state
        console.log(toJS(this.notificationsOut))
    }


    addNotificationsIn(notification) {
        //console.log(notification)
        //console.log(toJS(this.notificationsIn))

        if (notification.from !== this.user.id) {

            if (toJS(this.notificationsIn)[notification.about]) {
                //console.log("IF1")
                if (toJS(this.notificationsIn)[notification.about].indexOf(notification.from) !== -1) {
                    //console.log("IF2")
                    ;
                } else {
                    //console.log("ELSE2")
                    this.notificationsIn = toJS(this.notificationsIn)[notification.about].push(notification.from)
                    let objectToChange = toJS(this.notificationsIn)
                    //console.log(notification.from)
                    objectToChange[notification.about].push(notification.from)
                    this.notificationsIn = objectToChange
                }
            } else {
                //console.log("ELSE1")
                this.notificationsIn[notification.about] = [notification.from]
            }
        }
    }


    setAuth(bool) {
        this.isAuth = bool;
    }

    setUserDataAdded(bool) {
        this.isUserDataAdded = bool;
    }


    setChoice(status) {
        this.choice = status;
    }

    setMail(mail) {
        this.mail = mail;

    }

    setPassword(pass) {
        this.password = pass
    }


    setUser(user) {
        console.log("Setting USER from store")
        console.log(user)
        this.user = user;
    }


    setLoading(bool) {
        this.isLoading = bool;
    }

    setTrySign(bool) {
        this.isTriedToSign = bool;
    }

    setTryReg(bool) {
        this.isTriedToRegister = bool;
    }




    //ниже методы (функции) класса Store, для логина и для регистрации
    async login(email, password) {
        this.setTrySign(true)
        console.log("login happened")
        try {
            //запрашиваем авторизацию с помощью соответствующего сервиса, в нем уже создан инстанс аксиоса для этого
            const response = await AuthService.login(email, password);
            console.log(response.data)



            // function setCookie(name, value, expirationDays) {
            //
            //     const date = new Date();
            //     date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
            //     const expires = "expires=" + date.toUTCString();
            //     document.cookie = name + "=" + value + ";" + expires + ";path=/";
            // }


            // Пример использования функции для установки refreshToken в cookies на 30 дней
            // const refreshToken = response.data.refreshToken;
            console.log("SETTING COOKIE 1")
            //console.log(refreshToken)
            //setCookie("refreshToken", refreshToken, 30);


            //из полученного ответа вытаскиваем токен доступа и сохраняем его в локальное хранилище
            //с именем token
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('currentUser', response.data.user.email)
            //ниже мутации mobx это тоже самое что функции изменения состояния, но я пока эту тему не проходил))
            this.setAuth(true);
            this.setUser(response.data.user)
            this.setWorkUnreadCounterOut(response.data.user.workUnreadCountOut)


            this.setErrorMessage("")

        } catch (e) {

            console.log(e.response?.data?.message)
            if (e.response.data.errors === "email") {
                this.setErrorMessage("Пользователь не зарегистрирован")
            }
            if (e.response.data.errors === "pass") {
                this.setErrorMessage("Неверные данные")
            }
        }
    }




    async registration(email, password, invite) {
        this.setTryReg(true)

        console.log("registration happened")
        try {
            const response = await AuthService.registration(email, password, invite);
            //console.log(response.data)


            // function setCookie(name, value, expirationDays) {
            //     const date = new Date();
            //     date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
            //     const expires = "expires=" + date.toUTCString();
            //
            //     // Добавляем атрибуты SameSite=None и Secure
            //     const cookieOptions = "path=/; SameSite=None; Secure";
            //
            //     // Склеиваем всё в одну строку
            //     const cookieString = `${name}=${value}; ${expires}; ${cookieOptions}`;
            //
            //     // Устанавливаем куку
            //     document.cookie = cookieString;
            // }

            function setCookie(name, value, expirationDays) {
                const date = new Date();
                date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
                const expires = "expires=" + date.toUTCString();
                document.cookie = name + "=" + value + ";" + expires + ";path=/";
            }

            // Пример использования функции для установки refreshToken в cookies на 30 дней
            const refreshToken = response.data.refreshToken;
            console.log("SETTING COOKIE 2")
            //console.log(refreshToken)
            setCookie("refreshToken", refreshToken, 30);


            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('currentUser', response.data.email)
            this.setAuth(true);
            this.setUser(response.data)

            return "OK"

        } catch (e) {


            this.setErrorMessage(e.response?.data?.message)


            console.log(e.response?.data?.message)

        }

    }

    async logout() {

        try {

            await AuthService.logout();
            //в случае логаут удаляем токен из локального хранилища
            localStorage.removeItem('token');
            localStorage.removeItem('currentUser')
            this.whitePointer = ""
            this.setAuth(false);
            this.setUser({})

        } catch (e) {
            console.log(e)

        }

    }



    async deleteUser(user) {
        try {

            await $api.post('/users', {user})


        } catch (e) {
            console.log(e)
        }
    }


    //эта функция является обрезанной версией функции checkAuth(), используется для обновления данных юзера
    //при взятии/закрытии/отмене заказа
    async updateUserInfo() {
        try {
            console.log("Refresh init from store 1")
            const response = await axios.get(`${API_URL}/refresh`, {withCredentials: true})
            console.log(response.data)
            this.setUser(response.data.user)

        } catch (e) {
            console.log(e.response?.data?.message)
            console.log(e)
        }
    }



    async checkAuth() {
        this.setLoading(true);
        console.log("check auth happened")
        try {

            const response = await axios.get(`${API_URL}/refresh`, {withCredentials: true})

            console.log(response.data)
            localStorage.setItem('token', response.data.accessToken);
            // localStorage.setItem('currentUser', response.data.email)
            this.setAuth(true);
            this.setUser(response.data.user)
            this.setWorkUnreadCounterOut(response.data.user.workUnreadCountOut)






            // console.log("Refresh init from store 2")
            // const response = await $api.get(`${API_URL}/refresh`, {withCredentials: true})
            // //console.log(response)
            //
            // // function setCookie(name, value, expirationDays) {
            // //
            // //     console.log(name, value, expirationDays)
            // //     const date = new Date();
            // //     date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
            // //     const expires = "expires=" + date.toUTCString();
            // //
            // //     // Добавляем атрибуты SameSite=None и Secure
            // //     const cookieOptions = "path=/; SameSite=None";
            // //
            // //     // Склеиваем всё в одну строку
            // //     const cookieString = `${name}=${value}; ${expires}; ${cookieOptions}`;
            // //
            // //     // Устанавливаем куку
            // //     document.cookie = cookieString;
            // // }
            //
            // function setCookie(name, value, expirationDays) {
            //     const date = new Date();
            //     date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
            //     const expires = "expires=" + date.toUTCString();
            //     document.cookie = name + "=" + value + ";" + expires + ";path=/";
            // }
            //
            // // Пример использования функции для установки refreshToken в cookies на 30 дней
            // const refreshToken = response.data.refreshToken;
            // console.log("SETTING COOKIE 3")
            //
            // setCookie("refreshToken", refreshToken, 30);
            //
            //
            // localStorage.setItem('token', response.data.accessToken);
            // this.setAuth(true);
            // this.setUser(response.data.user)
            // this.setPrivateUnreadCounter(Number(response.data.user.privateUnreadCount))
            // this.setWorkUnreadCounterOut(Number(response.data.user.workUnreadCountOut))
            // this.setWorkUnreadCounterIn(Number(response.data.user.workUnreadCountIn))
            // this.setWorkUnreadCounterModer(Number(response.data.user.workUnreadCountModer))
            // //console.log(response.data.user.isActivated)
        } catch (e) {
            console.log(e.response?.data?.message)
        } finally {
            this.setLoading(false)
        }
    }


    async makeInvite(inviteFor) {
        try {
            const currentUser = localStorage.getItem('currentUser');
            const response = await $api.post('/invite', {currentUser, inviteFor})

            return response.data


        } catch (e) {
            console.log(e)
        }

    }

    async saveUserData(id, name, lastName, vkLink, phone, disciplines) {

        this.setLoading(true)

        try {
            const response = await $api.post('/save_user', {id, name, lastName, vkLink, phone, disciplines})
            console.log(response.status)
            this.setUser(response.data)

        } catch (e) {
            console.log("Произошла ошибка saveUserData в store")
            console.log(e)
        } finally {
            this.setLoading(false)
        }
    }


}