import React, {useState, useContext, useEffect, useRef} from 'react';
import {Context} from "../index";
import $api from "../http";
import InviteMaker from "./InviteMaker";
import UserSearch from "./UserSearch";
import CheaterProcessor from "./helpers/CheaterProcessor";
import OrderViewer from "./helpers/OrderViwer"
import PaymentViewer from "./helpers/PaymentViewer";
import {API_IP} from "../http";
import ModalCreator from "./helpers/ModalCreator";
import UserPicker from "./helpers/UserPicker";
import MyEditor from "./helpers/AvatarEditor";

const AdminOffice = () => {

    const {store} = useContext(Context)

    const [mode, setMode] = useState()
    const [subMode, setSubMode] = useState()
    const [connected, setConnected] = useState(false)
    const [emptyMassive, setEmptyMassive] = useState(false)

    const [inviteRefresher, setInviteRefresher] = useState(0)

    const [activeChatCreate, setActiveChatCreate] = useState("no")
    const [activeChatUsers, setActiveChatUsers] = useState("no")
    const [activeChatDelete, setActiveChatDelete] = useState("no")
    const [chatName, setChatName] = useState("")
    const [chatId, setChatId] = useState("")
    const [activeChatInfo, setActiveChatInfo] = useState()
    const [idAlreadyExists, setIdAlreadyExists] = useState(false)
    const [existingChatIds, setExistingChatIds] = useState([])


    const [selectedUser, setSelectedUser] = useState()
    const [userReviews, setUserReviews] = useState([])

    async function getUsersReviews(user) {
        const response = await $api.get(`/get_reviews/${user.id}`)
        const reviewsData = JSON.parse(response.data)
        setUserReviews(reviewsData)
    }


    useEffect(() => {
        if(selectedUser) {
            console.log(selectedUser)
            getUsersReviews(selectedUser)
        }
    }, [selectedUser])


    const socket = useRef()

    useEffect(() => {
        store.editWhitePointer({place: "Хижина админа:", color: "gold-color"})

        socket.current = new WebSocket(`wss://${API_IP}:5323`)


        socket.current.onopen = () => {
            setConnected(true)
            const message = {
                event: 'connection',
                //username: userName,
                id: store.user.id
            }
            socket.current.send(JSON.stringify(message))
            console.log("WS-ADMIN подключение установлено")


        }

        socket.current.onmessage = async (event) => {
            const message = JSON.parse(event.data)

            console.log(message)

            if (message.event === 'invite-refresh') {

                setInviteRefresher(message.code)

            }

        }

        socket.current.onclose = () => {
            console.log("Подключение закрылось")
            setConnected(false)

        }

        socket.current.onerror = () => {
            console.log("Ошибка сокета")

        }


        return () => {
            // Отписываемся от событий WebSocket
            socket.current.close();
        };


    }, [])


    const [cat1, setCat1] = useState("")
    const [cat2, setCat2] = useState("")
    const [cat3, setCat3] = useState("")

    const [statusMessage, setStatusMessage] = useState("Ready")

    const [classifier, setClassifier] = useState()
    const [classifierNew, setClassifierNew] = useState()
    const [chatsInfo, setChatsInfo] = useState()
    const [section, setSection] = useState()
    const [science, setScience] = useState()
    const [discipline, setDiscipline] = useState()
    const [allUsers, setAllUsers] = useState()


    function disciplineReset() {
        setCat1("")
        setCat2("")
        setCat3("")
        setScience()
        setSection(null)
        setDiscipline()
    }



    async function disciplineRemove() {
        if(cat3) {

            console.log("Removing of -", cat3)
            const response = await $api.post('/classifier_remove', {cat1, cat2, cat3})

            if (response.data === "OK") {
                setStatusMessage("Дисциплина удалена")
                setCat1("")
                setCat2("")
                setCat3("")
                setScience()
                setSection(null)
                setDiscipline()
                loadClassifier()
            }



        } else {
            if(cat2) {
                if(emptyMassive) {
                    console.log("Removing of -", cat2)
                    const response = await $api.post('/classifier_remove', {cat1, cat2})

                    console.log(response.data)

                    if (response.data === "OK") {
                        setStatusMessage("Дисциплина удалена")
                        setCat1("")
                        setCat2("")
                        setCat3("")
                        setScience()
                        setSection(null)
                        setDiscipline()
                        loadClassifier()
                    } else {
                        setStatusMessage("Произошла ошибка")
                    }

                } else {
                    setStatusMessage("Категория не пустая, ее нельзя удалять")

                }
            } else {
                setStatusMessage("Нечего удалять")

            }
        }

        setTimeout(() => {
            setStatusMessage("Ready")


        }, 3000)
    }


    async function classifierBuild() {

        if (cat1) {
            try {
                const response = await $api.post('/classifier_build', {cat1, cat2, cat3})

                if(response.data === "ALREADY") {
                    setStatusMessage("Такая дисциплина уже существует")
                }

                if(response.data === "OK") {
                    setStatusMessage("Дисциплина добавлена")
                    setCat1("")
                    setCat2("")
                    setCat3("")
                    setScience()
                    setSection(null)
                    setDiscipline()
                    loadClassifier()
                }

                if(response.data === "FAIL") {
                    setStatusMessage("Ошибка сервера")
                }

                if(!cat3) {
                    setCat2("")
                }
                setCat3("")



                setTimeout(() => {
                    setStatusMessage("Ready")
                    // if(!cat2) {
                    //     document.getElementById("cat2").focus()
                    // } else {
                    //     document.getElementById("cat3").focus()
                    // }


                }, 3000)

            } catch (e) {
                setStatusMessage(e.message)
            }
        }


    }


    async function loadClassifier() {
        const response = await $api.get('/get_classifier')
        console.log(response.data)
        setClassifierNew(response.data.new)

    }


    async function loadChatsInfo() {
        const response = await $api.get('/get_chats_info')
        console.log(response.data)
        setChatsInfo(response.data)

        const IDS = response.data.map(chat => chat.chat_id)
        setExistingChatIds(IDS)


    }


    async function loadUsersInfo() {
        const response = await $api.get('/get_users')
        setAllUsers(response.data)
    }


    async function deleteChat(id) {
        console.log(chatName)
        console.log(id)
        const response = await $api.post('/delete_chat', {chatName, chat_id: id})
        if(response.data === "OK") {
            setChatId("")
            setChatName("")
            loadChatsInfo()
        }
    }

    async function creatChat() {
        console.log(chatName)
        console.log(chatId)
        const response = await $api.post('/create_chat', {chatName, chatId})
        if(response.data === "OK") {
            setChatId("")
            setChatName("")
            loadChatsInfo()
        }
    }


    function checkChatExist(id) {

        if(existingChatIds.includes(id)) {
            return true
        } else {
            return false
        }
    }


    async function addUserToChat(id) {

        const response = await $api.post('/add_user_to_chat', {user_id: id, chat_id: activeChatInfo.chat_id})
        if(response.data === "OK") {
            setActiveChatInfo(prevState => {
                const updatedChatData = { ...prevState };
                updatedChatData.chat_users.push(id);
                return updatedChatData
            })

            setChatsInfo(prevState => {
                return prevState.map((chat) => {
                    if (chat.chat_id === activeChatInfo.chat_id) {
                        // Если _id совпадает, обновляем
                        const updatedChatUsers = [...chat.chat_users];
                        updatedChatUsers.push(id);
                        return { ...chat, chat_users: updatedChatUsers};
                    }
                    // В противном случае оставляем без изменений
                    return chat;
                });
            })
        }
    }


    async function removeUserFromChat(id) {
        const response = await $api.post('/remove_user_from_chat', {user_id: id, chat_id: activeChatInfo.chat_id})

        if(response.data === "OK") {

            setActiveChatInfo(prevState => {
                // Создаем копию объекта состояния
                const updatedChatData = { ...prevState };

                // Используем метод filter для создания нового массива chat_users без элемента id
                updatedChatData.chat_users = updatedChatData.chat_users.filter(userId => userId !== id);

                return updatedChatData;
            });

            setChatsInfo(prevState => {
                return prevState.map((chat) => {
                    if (chat.chat_id === activeChatInfo.chat_id) {
                        // Если chat_id совпадает, удаляем id из массива chat_users
                        const updatedChatUsers = chat.chat_users.filter(userId => userId !== id);
                        return { ...chat, chat_users: updatedChatUsers };
                    }
                    // В противном случае оставляем чат без изменений
                    return chat;
                });
            });
        }
    }


    useEffect(() => {

        if(!classifierNew && mode === "disciplines") {
            loadClassifier()
        } else if (!chatsInfo && mode === "chats") {
            loadChatsInfo()
        }
        if (!allUsers && mode === "chats") {
            loadUsersInfo()
        }


    }, [mode])



    return (
        <div className={"admin-office-main-wrapper"}>
            <div className={"admin-office-switch-container"}>
                <button className={`btn ${mode === "invite" ? "btn-warning" : "btn-primary" } admin-switch-btn`} onClick={() => setMode("invite")}>Инвайты</button>
                <button
                    className={`btn ${mode === "different" ? "btn-warning" : "btn-primary" } admin-switch-btn`}
                    onClick={() => {
                        setMode("different")
                        setSubMode("reviews")
                    }}
                >Разное</button>
                <button
                    className={`btn ${mode === "users" ? "btn-warning" : "btn-primary" } admin-switch-btn`}
                    onClick={() => {
                        setMode("users")
                        setSubMode("members")
                    }}
                >Люди</button>
                <button className={`btn ${mode === "disciplines" ? "btn-warning" : "btn-primary"} admin-switch-btn`} onClick={() => setMode("disciplines")}>Дисциплины</button>
                <button className={`btn ${mode === "chats" ? "btn-warning" : "btn-primary" } admin-switch-btn`} onClick={() => setMode("chats")}>Чаты</button>
                <button className={`btn ${mode === "orders" ? "btn-warning" : "btn-primary" } admin-switch-btn`} onClick={() => setMode("orders")}>Заказы</button>
                <button className={`btn ${mode === "payments" ? "btn-warning" : "btn-primary" } admin-switch-btn`} onClick={() => setMode("payments")}>Платежи</button>
            </div>
            {mode === "users"
                ?
                <div className={"admin-office-switch-container"}>
                    <button className={`btn ${subMode === "members" ? "btn-warning" : "btn-primary" } admin-switch-btn`} onClick={() => setSubMode("members")}>Участники</button>
                    <button className={`btn ${subMode === "cheaters" ? "btn-warning" : "btn-primary" } admin-switch-btn`} onClick={() => setSubMode("cheaters")}>База мошенников</button>
                </div>
                :
                null
            }
            {mode === "different"
                ?
                <div className={"admin-office-switch-container"}>
                    <button className={`btn ${subMode === "reviews" ? "btn-warning" : "btn-primary" } admin-switch-btn`} onClick={() => setSubMode("reviews")}>Отзывы</button>
                    <button className={`btn ${subMode === "none" ? "btn-warning" : "btn-primary" } admin-switch-btn`} onClick={() => setSubMode("none")}>Неведомая кнопка</button>
                </div>
                :
                null
            }
            <hr/>

            {mode === "invite"
                ?
                <InviteMaker trigger={inviteRefresher}/>
                :
                null
            }

            {mode === "different"
                ?
                <div>
                    <button
                        className={"btn btn-warning"}
                        onClick={async() => {
                            const response = await $api.get(`/refresh_reviews`)
                        }}
                    >Обновить отзывы</button>

                    <UserPicker
                        placeholder={"Кого ищем..."}
                        setSelectedUser={setSelectedUser}
                        userReviews={userReviews}
                        setUserReviews={setUserReviews}
                    />
                </div>
                :
                null
            }

            {mode === "users"
                ?
                subMode === "members"
                    ?
                    <UserSearch admin={store.user.admin}/>
                    :
                    <CheaterProcessor admin={store.user.admin} user_id={store.user.id}/>
                :
                null
            }

            {mode === "disciplines"
                ?
                <div>
                    <div className={"adding-discipline-monitor"}>
                        <h4 className={`${statusMessage === "OK" ? "green-color" : "red"}`}>{statusMessage}</h4>
                        <input value={cat1} onChange={(e) => setCat1(e.target.value)}/>
                        <input id={"cat2"} value={cat2} onChange={(e) => setCat2(e.target.value)}/>
                        <input
                            id={"cat3"}
                            value={cat3}
                            onChange={(e) => setCat3(e.target.value)}
                            onKeyDown={(e) => {
                                if(e.key === "Enter") {
                                    classifierBuild()
                                }
                            }}
                        />


                    </div>
                    {/*{classifierNew*/}
                    {/*    ?*/}
                    {/*    <div className={"admin-classifier-wrapper"}>*/}
                    {/*        <div className={"classifier-section-container"}>*/}
                    {/*            <select*/}
                    {/*                className={"width-100p"}*/}
                    {/*                name={"section"}*/}
                    {/*                defaultValue={""}*/}
                    {/*                value={section ? section.section_name : ""}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    const selectedSection = classifierNew.find(item => JSON.stringify(item.section) === e.target.value);*/}

                    {/*                    console.log(selectedSection.section)*/}
                    {/*                    setSection(selectedSection.section);*/}
                    {/*                    setCat1(selectedSection.section_name)*/}
                    {/*                    //setDisString2()*/}
                    {/*                    setScience("");*/}
                    {/*                    setDiscipline("");*/}
                    {/*                    setCat2("")*/}
                    {/*                    setCat3("")*/}
                    {/*                    //clearErrors('section');*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                <option value={""} disabled>*/}
                    {/*                    Раздел*/}
                    {/*                </option>*/}
                    {/*                {classifierNew.map(item1 => (*/}
                    {/*                    <option key={item1._id} value={JSON.stringify(item1.section)}>*/}
                    {/*                        {item1.section_name}*/}
                    {/*                    </option>*/}
                    {/*                ))}*/}
                    {/*            </select>*/}
                    {/*        </div>*/}

                    {/*        /!*<br/>*!/*/}
                    {/*        {section*/}
                    {/*            ?*/}
                    {/*            <div className={"new-order-classifier-science-discipline-container"}>*/}
                    {/*                <div className={"classifier-section-container"}>*/}
                    {/*                    <select*/}
                    {/*                        className={"width-100p"}*/}

                    {/*                        name={"science"}*/}
                    {/*                        value={science ? JSON.stringify(science) : ""}*/}
                    {/*                        onChange={(e) => {*/}
                    {/*                            const selectedScience = JSON.parse(e.target.value);*/}
                    {/*                            setScience(selectedScience);*/}
                    {/*                            //setClassifierId()*/}
                    {/*                            const selectedSection = section.find(item => JSON.stringify(item.science) === e.target.value);*/}
                    {/*                            setEmptyMassive(selectedSection?.empty_massive || false);*/}


                    {/*                            if (selectedSection?.empty_massive) {*/}
                    {/*                                // Если empty_massive равен true, устанавливаем disId первой дисциплины в массиве science*/}
                    {/*                                //setClassifierId(selectedSection?.science[0]?._id);*/}
                    {/*                            } else {*/}
                    {/*                                // Если empty_massive равен false, сбрасываем disId*/}
                    {/*                                //setClassifierId(null);*/}
                    {/*                            }*/}


                    {/*                            const scienceName = section.find(item => JSON.stringify(item.science) === e.target.value)?.science_name;*/}
                    {/*                            setCat2(scienceName);*/}
                    {/*                            setDiscipline("");*/}
                    {/*                            //clearErrors('science');*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        <option value={""} disabled>*/}
                    {/*                            Область*/}
                    {/*                        </option>*/}
                    {/*                        {section.map(item2 => (*/}
                    {/*                            <option key={item2._id} value={JSON.stringify(item2.science)}>*/}
                    {/*                                {item2.science_name}*/}
                    {/*                            </option>*/}
                    {/*                        ))}*/}
                    {/*                    </select>*/}

                    {/*                </div>*/}
                    {/*                /!*<br/>*!/*/}
                    {/*                {science && !emptyMassive*/}
                    {/*                    ?*/}
                    {/*                    <div className={"classifier-section-container"}>*/}
                    {/*                        <select*/}
                    {/*                            className={"width-100p"}*/}

                    {/*                            name={"discipline"}*/}
                    {/*                            value={discipline}*/}
                    {/*                            onChange={(e) => {*/}
                    {/*                                const selectedDisciplineId = e.target.options[e.target.selectedIndex].getAttribute('data-id');*/}
                    {/*                                //setClassifierId(selectedDisciplineId);*/}
                    {/*                                setDiscipline(e.target.value)*/}
                    {/*                                setCat3(e.target.value)*/}
                    {/*                                console.log(e.target.value)*/}
                    {/*                                //clearErrors('discipline');*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            <option value={""} disabled>*/}
                    {/*                                Дисциплина*/}
                    {/*                            </option>*/}
                    {/*                            {science.map(item3 => (*/}
                    {/*                                <option key={item3._id} value={item3.discipline} data-id={item3._id} title={item3.discipline}>*/}
                    {/*                                    {item3.discipline}*/}
                    {/*                                </option>*/}
                    {/*                            ))}*/}

                    {/*                        </select>*/}

                    {/*                    </div>*/}
                    {/*                    :*/}
                    {/*                    null*/}
                    {/*                }*/}
                    {/*            </div>*/}
                    {/*            :*/}
                    {/*            null*/}
                    {/*        }*/}

                    {/*    </div>*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                    {classifierNew
                        ?
                        <div className={"admin-classifier-wrapper"}>
                            <div className={"classifier-section-container"}>
                                <select
                                    className={"width-100p"}
                                    name={"section"}
                                    defaultValue={""}
                                    value={section ? section.section_name : ""}
                                    onChange={(e) => {
                                        const selectedSection = classifierNew.find(item => JSON.stringify(item.section) === e.target.value);

                                        console.log(selectedSection.section)
                                        setSection(selectedSection.section);
                                        setCat1(selectedSection.section_name)
                                        setScience("");
                                        setDiscipline("");
                                        setCat2("")
                                        setCat3("")
                                    }}
                                >
                                    <option value={""} disabled>
                                        Раздел
                                    </option>
                                    {classifierNew.map(item1 => (
                                        <option key={item1._id} value={JSON.stringify(item1.section)}>
                                            {item1.section_name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {section
                                ?
                                <div className={"new-order-classifier-science-discipline-container"}>
                                    <div className={"classifier-section-container"}>
                                        <select
                                            className={"width-100p"}
                                            name={"science"}
                                            value={science ? JSON.stringify(science) : ""}
                                            onChange={(e) => {
                                                const selectedScience = JSON.parse(e.target.value);
                                                setScience(selectedScience);
                                                const selectedSection = section.find(item => JSON.stringify(item.science) === e.target.value);
                                                setEmptyMassive(selectedSection?.empty_massive || false);

                                                if (selectedSection?.empty_massive) {
                                                    // Убраны закомментированные строки, так как setClassifierId не используется в дальнейшем
                                                } else {
                                                    // Убраны закомментированные строки, так как setClassifierId не используется в дальнейшем
                                                }

                                                const scienceName = section
                                                    .map(item2 => item2.science_name)
                                                    .filter((value, index, self) => self.indexOf(value) === index)
                                                    .sort()
                                                    .find(scienceName => JSON.stringify(section.find(item => item.science_name === scienceName).science) === e.target.value);

                                                setCat2(scienceName);
                                                setDiscipline("");
                                            }}
                                        >
                                            <option value={""} disabled>
                                                Область
                                            </option>
                                            {section
                                                .map(item2 => item2.science_name)
                                                .filter((value, index, self) => self.indexOf(value) === index)
                                                .sort()
                                                .map(scienceName => (
                                                    <option key={scienceName} value={JSON.stringify(section.find(item => item.science_name === scienceName).science)}>
                                                        {scienceName}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>

                                    {science && !emptyMassive
                                        ?
                                        <div className={"classifier-section-container"}>
                                            <select
                                                className={"width-100p"}
                                                name={"discipline"}
                                                value={discipline}
                                                onChange={(e) => {
                                                    const selectedDisciplineId = e.target.options[e.target.selectedIndex].getAttribute('data-id');
                                                    setDiscipline(e.target.value)
                                                    setCat3(e.target.value)
                                                }}
                                            >
                                                <option value={""} disabled>
                                                    Дисциплина
                                                </option>
                                                {science
                                                    .map(item3 => item3.discipline)
                                                    .filter((value, index, self) => self.indexOf(value) === index)
                                                    .sort()
                                                    .map(disciplineName => (
                                                        <option
                                                            key={disciplineName}
                                                            value={disciplineName}
                                                            data-id={science.find(item => item.discipline === disciplineName)._id}
                                                            title={disciplineName}
                                                        >
                                                            {disciplineName}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }


                    <div className={"admin-dis-management-buttons margin-top-20"}>

                        <button
                            className={"btn btn-warning"}
                            onClick={disciplineReset}

                        >Сброс</button>
                        <button
                            className={"btn btn-warning margin-left-20"}
                            onClick={classifierBuild}

                        >Добавить</button>
                        <button
                            className={"btn btn-danger margin-left-20"}
                            onClick={disciplineRemove}

                        >Удалить</button>
                    </div>

                    {/*{classifier*/}
                    {/*    ?*/}
                    {/*    <div className={"admin-classifier-wrapper"}>*/}
                    {/*        <select*/}
                    {/*            defaultValue={""}*/}
                    {/*            value={section}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                setSection(e.target.value)*/}
                    {/*                setCat1(e.target.value)*/}
                    {/*                setScience("")*/}
                    {/*                setDiscipline("")*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <option value={""} disabled>*/}
                    {/*                Раздел*/}
                    {/*            </option>*/}
                    {/*            {Object.keys(classifier).sort().map(cat1 => (*/}
                    {/*                <option key={cat1} value={cat1}>*/}
                    {/*                    {cat1}*/}
                    {/*                </option>*/}
                    {/*            ))}*/}
                    {/*        </select>*/}
                    {/*        {section*/}
                    {/*            ?*/}
                    {/*            <div className={"admin-classifier-science-discipline-container"}>*/}
                    {/*            <select*/}

                    {/*                value={science}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    setScience(e.target.value)*/}
                    {/*                    setCat2(e.target.value)*/}
                    {/*                    setDiscipline("")*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                <option value={""} disabled>*/}
                    {/*                    Область*/}
                    {/*                </option>*/}
                    {/*                {Object.keys(classifier[section]).sort().map(cat2 => (*/}
                    {/*                    <option key={cat2} value={cat2}>*/}
                    {/*                        {cat2}*/}
                    {/*                    </option>*/}
                    {/*                ))}*/}
                    {/*            </select>*/}
                    {/*            {classifier[section][science]?.length*/}
                    {/*                ?*/}
                    {/*                <select value={discipline} onChange={(e) => setDiscipline(e.target.value)}>*/}
                    {/*                    <option value={""} disabled>*/}
                    {/*                        Дисциплина*/}
                    {/*                    </option>*/}
                    {/*                    {classifier[section][science].sort().map(cat3 => (*/}
                    {/*                        <option key={cat3} value={cat3}>*/}
                    {/*                            {cat3}*/}
                    {/*                        </option>*/}
                    {/*                    ))}*/}
                    {/*                </select>*/}
                    {/*                :*/}
                    {/*                null*/}
                    {/*            }*/}
                    {/*            </div>*/}
                    {/*            :*/}
                    {/*            null*/}
                    {/*        }*/}

                    {/*    </div>*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                </div>
                :
                null
            }


            {mode === "chats"
                ?
                <div className={"invite-container"}>
                    <button
                        className={"btn btn-primary invite-btn"}
                        onClick={() => {
                            setActiveChatCreate("progress")
                            setTimeout(() => {
                                setActiveChatCreate("active")
                            }, 100)
                        }}
                    >Создать чат</button>
                    <table className="table invite-table">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Название</th>
                            <th scope="col">Идентификатор</th>
                            <th scope="col">Участники</th>
                            <th scope="col">Управление</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr key={"disput"}>
                            <td>{0}</td>
                            <td>Светский диспут</td>
                            <td>disput</td>
                            <td></td>
                            <td>

                                <MyEditor
                                    className={"margin-left-20"}
                                    exists={true}
                                    purpose={"disput"}
                                    type={"chat"}
                                />
                            </td>
                        </tr>

                        <tr key={"admin"}>
                            <td>{"00"}</td>
                            <td>Админка</td>
                            <td>admin</td>
                            <td></td>
                            <td>

                                <MyEditor
                                    className={"margin-left-20"}
                                    exists={true}
                                    purpose={"admin"}
                                    type={"chat"}
                                />
                            </td>
                        </tr>

                        <tr key={"arbitr"}>
                            <td>{"000"}</td>
                            <td>Арбитраж</td>
                            <td>arbitr</td>
                            <td></td>
                            <td>

                                <MyEditor
                                    className={"margin-left-20"}
                                    exists={true}
                                    purpose={"arbitr"}
                                    type={"chat"}
                                />
                            </td>
                        </tr>

                        {chatsInfo?.map((chat, index) => {
                            return <tr key={chat._id}>
                                <td>{index + 1}</td>
                                <td>{chat.chatName}</td>
                                <td>{chat.chat_id}</td>
                                <td>
                                    <button
                                        className={"btn btn-primary"}
                                        onClick={() => {
                                            setActiveChatInfo(chat)
                                            setActiveChatUsers("progress")
                                            setTimeout(() => {
                                                setActiveChatUsers("active")
                                            }, 100)
                                        }}

                                    >Показать</button></td>
                                <td className={"td-flex-row"}>
                                    <MyEditor
                                        exists={true}
                                        purpose={chat.chat_id}
                                        type={"chat"}
                                    />
                                    <button
                                        className={"btn btn-danger margin-left-20"}
                                        onClick={() => {
                                            setActiveChatInfo(chat)
                                            setActiveChatDelete("progress")
                                            setTimeout(() => {
                                                setActiveChatDelete("active")
                                            }, 100)
                                        }}

                                    >Удалить</button>
                                </td>
                            </tr>})}

                        </tbody>
                    </table>

                </div>
                :
                null
            }

            {mode === "orders"
                ?
                <OrderViewer/>
                :
                null
            }


            {mode === "payments"
                ?
                <PaymentViewer userId={store.user.id}/>
                :
                null
            }


            <ModalCreator
                active={activeChatCreate}
                setActive={setActiveChatCreate}
                action={() => creatChat()}
                action2={checkChatExist}
                message={`Создание чата`}
                purpose={"chat-creation"}
                parameter1={chatName}
                setParameter1={setChatName}
                parameter2={chatId}
                setParameter2={setChatId}
                parameter3={idAlreadyExists}
                setParameter3={setIdAlreadyExists}
            />

            <ModalCreator
                active={activeChatDelete}
                setActive={setActiveChatDelete}
                action={deleteChat}
                //action2={checkChatExist}
                message={`Для удаления чата "${activeChatInfo?.chatName}" напишите полностью его название`}
                redMessage={'Внимание, удаление чата, повлечет за собой потерю всех сообщений и прикрепленных файлов. Действие является необратимым!'}
                purpose={"chat-delete"}
                parameter1={chatName}
                setParameter1={setChatName}
                parameter2={activeChatInfo}
                //setParameter2={setChatId}

            />

            <ModalCreator
                active={activeChatUsers}
                setActive={setActiveChatUsers}
                alignFlag={true}
                action={addUserToChat}
                action2={removeUserFromChat}
                message={`Список пользователей чата \n"${activeChatInfo?.chatName}"`}
                purpose={"chat-user-list"}
                parameter1={activeChatInfo}
                //setParameter1={setChatName}
                parameter2={allUsers}
                //setParameter2={setChatId}
                parameter3={idAlreadyExists}
                setParameter3={setIdAlreadyExists}
            />


        </div>
    );
};

export default AdminOffice;