import React, {useEffect, useContext, useState, useRef, useCallback} from 'react';
import {Context} from "../index";
import $api from "../http";
import {toJS} from "mobx";
import {observer} from 'mobx-react-lite';
import SvgAnswer from "../img/answer-arrow.svg"; // Путь к SVG-файлу
import SvgForward from "../img/forward-arrow.svg";
import SvgPencil from "../img/pencil-svgrepo-com.svg";
import SvgGoToMsg from "../img/go-to-msg.svg";
import SvgSend from "../img/send.svg";
import SvgCancel from "../img/cancel.svg"
import SvgPaperClip from "../img/paper-clip.svg"
import SvgForwardSend from "../img/forward-msg.svg"
import NestedMessageModal from "./helpers/NestedMessageModal";
import SvgArrowDown from "../img/arrow-down.svg"; // Путь к SVG-файлу
import {API_URL} from "../http";
import {
    getMinutesBetweenDates,
    highlightTextAroundPattern,
    processMessageText,
    chatNameResolver, assignHeight, assignWidth
} from "./helpers/repeat_functions";
import ModalDialog from "./helpers/ModalDialog";
import SvgUpload from "../img/upload-minimalistic.svg";
import SvgSearchLens from "../img/search-lens.svg";
import SvrSearchLensRed from "../img/search-lens-red.svg"
import {v4} from 'uuid'
import ModalImgViewer from "./helpers/ModalImgViewer";
import SvgSoundOn from "../img/sound-on.svg";
import SvgSoundOff from "../img/sound-off.svg";
import SvgOpenedEye from "../img/opened-eye.svg";
import SvgClosedEye from "../img/eye-closed.svg";
import SvgCheckUnread from "../img/checkmark-unread.svg"
import SvgCheckRead from "../img/checkmark-read.svg"
import SvgRedDot from "../img/red-dot.svg"
import SvgWhiteDot from "../img/white-dot.svg"
import SvgRedCross from "../img/red-cross.svg"


const PrivateMessages = ({notifySocket, newPrivateMessage, privateEditingData, typingUserPrivate, privateServiceMessage, setPrivateServiceMessage}) => {

    const {store} = useContext(Context)


    store.setPageId("private")

    const nowDate = Date.now()
    let lastDate = Date.now()
    let newMessagesLine = false


    const storedSoundOn = localStorage.getItem(`sound_private`);
    const initialSoundOn = storedSoundOn ? JSON.parse(storedSoundOn) : true;

    const [soundOn, setSoundOn] = useState(initialSoundOn);
    const [lastMessagesText, setLastMessagesText] = useState({})


    const message_notification_sound = new Audio('/sounds/message2.mp3')

    function playSound() {
        message_notification_sound.play()
        new Notification('Тихое уведомление', { silent: true });
    }


    const stopMakingObserver = useRef(false)

    //это нужно чтобы отметить элемент на страничке, чтобы потом повесить на него слушатель события
    const myRef = useRef();
    const doScroll = useRef(true)
    const activeUserRef = useRef()


    const [printedMessage, setPrintedMessage] = useState("")

    const [activeFileDoesntExist, setActiveFileDoesntExist] = useState("no")


    const [imageSource, setImageSource] = useState()
    const [activeModalImgViewer, setActiveModalImgViewer] = useState("no")

    const [errorMessage, setErrorMessage] = useState({show: false, text: ""})
    const [pinnedFiles, setPinnedFiles] = useState([])
    const [isFilesPinned, setIsFilesPinned] = useState(false)
    const [activeUser, setActiveUser] = useState()
    const [messageList, setMessageList] = useState({})
    const [userList, setUserList] = useState([])
    const [addUserToList, setAddUserToList] = useState(false)

    // const [unreadUsers, setUnreadUsers] = useState([])
    const [unreadMessages, setUnreadMessages] = useState({})

    const [hasNewMessages, setHasNewMessages] = useState(false);
    const isMappingInProgressRef = useRef(false)

    const [spinner, setSpinner] = useState(false)

    const [activeMessageModal, setActiveMessageModal] = useState("no")
    const [lastSteps, setLastSteps] = useState([])

    const [showArrowDown, setShowArrowDown] = useState(false)
    const [messagesToRoll, setMessagesToRoll] = useState([])
    const [usersForNextFetching, setUsersForNextFetching] = useState([])
    const [usersForPrevFetching, setUsersForPrevFetching] = useState([])

    const [forwardToChat, setForwardToChat] = useState(false)
    const [forwardingChatList, setForwardingChatList] = useState([])

    const [disableForward, setDisableForward] = useState(false)

    const [sortedUsersIds, setSortedUsersIds] = useState([])


    const isChatSelected = (chat) => forwardingChatList.some(selectedChat => selectedChat._id === chat._id);

    const toggleForwardingChat = (chat) => {
        setForwardingChatList(prev => {
            const newForwardingChatState = [...prev];
            const chatIndex = newForwardingChatState.findIndex(selectedChat => selectedChat._id === chat._id);

            if (chatIndex !== -1) {
                newForwardingChatState.splice(chatIndex, 1);
            } else {
                newForwardingChatState.push(toJS(chat));
            }

            return newForwardingChatState;
        });
    };



    const temporaryMessageStore = useRef({})

    const [tempStorage, setTempStorage] = useState({})

    const textAreaRef = useRef()


    const [messageSearching, setMessageSearching] = useState(false)
    const [foundedMessages, setFoundedMessages] = useState([])
    const [searchMessageValue, setSearchMessageValue] = useState("")
    const [showFoundedList, setShowFoundedList] = useState(false)

    async function messageSearch(search_string) {
        console.log("SEARCHING FOR - ", search_string)
        console.log("opponent_id - ", activeUserRef.current)
        const response = await $api.get(`/private_message_search?my_id=${store.user.id}&opponent_id=${activeUserRef.current}&search_string=${search_string}`)
        console.log(response.data)
        setFoundedMessages(response.data)
        setShowFoundedList(true)

    }


    function stopMessageSearch() {
        setMessageSearching(false)
        setShowFoundedList(false)
        setFoundedMessages([])
        setSearchMessageValue("")
    }


    const searchMessageTimerRef = useRef()

    useEffect(() => {
        if(searchMessageValue.length >= 3) {

            clearTimeout(searchMessageTimerRef.current)

            searchMessageTimerRef.current = setTimeout(() => {
                messageSearch(searchMessageValue)
            }, 1000)

        } else {
            clearTimeout(searchMessageTimerRef.current)
            setShowFoundedList(false)
            setFoundedMessages([])

        }

    }, [searchMessageValue])



    useEffect(() => {



        function handleViewportResize() {
            // Ваш код для обработки изменения ширины viewport
            const sourceElement = document.getElementById('sourceElement');
            const targetElement = document.getElementById('targetElement');
            //const targetElement2 = document.getElementById('private-ballast');

            // Получаем ширину исходного элемента и устанавливаем ее для целевого элемента
            try {
                const sourceWidth = window.getComputedStyle(sourceElement).width;
                targetElement.style.width = sourceWidth;
            } catch (error) {
                // Обработка ошибки
                ;
            }

            //targetElement2.style.width = sourceWidth;
            console.log('Ширина viewport изменилась!');
            assignHeight("private-chat-top", "user-list")
            assignWidth("private-chat-user-list-wrapper", "user-list")

        }

        handleViewportResize()

        // Добавить слушатель события resize
        window.addEventListener('resize', handleViewportResize);


        window.addEventListener('scroll', function() {
            const fixedElement = document.getElementById('user-list');
            const scrollLeft = document.documentElement.scrollLeft;

            fixedElement.style.left = -scrollLeft + 'px';
        });



    }, [])


    useEffect(() => {

        console.log("EFFECT 1")

        store.editWhitePointer({place: "Личные сообщения:"})






    }, [])

    const [extremeValues, setExtremeValues] = useState({})

    //этот эффект помогает повесить слушатель события на элемент который помечен как ref
    //вот так через жопу это делается
    useEffect(() => {
        console.log("EFFECT 2")
        const element = myRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, [myRef, extremeValues])


    const scrollToInvokeRef = useRef(false)
    const invokedMessageIdRef = useRef()
    const [highLightedMessage, setHighLightedMessage] = useState()


    useEffect(() => {
        if(highLightedMessage) {
            console.log("RENDER BY HIGHLIGHT")
            renderMessages()

        }
    }, [highLightedMessage])


    async function goToQuotedMessage(event, quote) {
        if (!event.target.matches('.arrows')
            && !event.target.matches('.chat-pre-image')
            && !event.target.matches('a')
            && !event.target.matches('.no-click-to-go')

        ) {

            goToInvocation(quote._id ?? quote)
        }


        console.log("QUOTED MESSAGE ID - ", quote._id ?? quote)
    }


    async function goToInvocation(id) {

        const invoked_message_id = id //?? store.invocations[chatId][0].message_id

        let needDBRequest = true

        for(let message of messageList[activeUserRef.current]) {
            if(message._id === invoked_message_id) {
                console.log("ЕСТЬ ТАКОЕ СООБЩЕНИЕ!")
                needDBRequest = false
                break;
                break;
            }
        }

        // for(let message of messagesToRoll) {
        //     // console.log(message)
        //     // console.log(invoked_message_id)
        //     if(message.key === invoked_message_id) {
        //         needDBRequest = false
        //         break;
        //     }
        // }

        if(needDBRequest) {

            scrollToInvokeRef.current = true
            invokedMessageIdRef.current = invoked_message_id

            console.log("WE NEED TO MAKE REQUEST")

            getInvokedMessages(invoked_message_id)


        } else {

            console.log("WE HAVE INVOKED MESSAGE IN STATE")


            const targetElement = document.getElementById(invoked_message_id);
            const scrollContainer = myRef.current;

            console.log(targetElement)

            if (targetElement && scrollContainer) {

                const targetElementY = targetElement.offsetTop;
                const scrollContainerHeight = scrollContainer.clientHeight;

                scrollContainer.scrollTop = targetElementY - scrollContainerHeight / 1.5;

                console.log("ROLL TO REF CHANGING 5, to - ", invoked_message_id)
                rollToRef.current = invoked_message_id

                // Дополнительно, если вы хотите добавить плавность, вы можете использовать анимацию
                // scrollContainer.scrollTo({
                //     top: targetElementY - scrollContainerHeight / 2,
                //     behavior: 'smooth',
                // });
            }



            //scrollToInvokeRef.current = true
            //invokedMessageIdRef.current = invoked_message_id
            // const targetElement = document.getElementById(invoked_message_id);
            //
            // console.log(targetElement)
            // if (targetElement) {
            //     console.log("scrolling into view")
            //     await targetElement.scrollIntoView({
            //         block: 'center',
            //     });
            //
            // }


            setHighLightedMessage(invoked_message_id)
            setTimeout(() => {
                setHighLightedMessage()
            }, 2000)

        }

    }

    async function getInvokedMessages(invoked) {

        console.log("ARMED FUNCTION 1")

         console.log(invoked)
        // const response = await $api.get(`/get_invoked_messages?chatId=${chatId}&invoked=${invoked}`)
        const response = await $api.get(`/get_100_messages?my_id=${store.user.id}&sender=${activeUserRef.current}&invoke=${invoked}`)
        console.log(response.data)
        // await setMessages(response.data.messages)

        console.log(extremeValues)

        extremeValuesRef.current[activeUserRef.current].firstId = response.data.extremeValues.firstId
        extremeValuesRef.current[activeUserRef.current].lastId = response.data.extremeValues.lastId


        setExtremeValues(prevState => {
            const newState = { ...prevState }
            newState[activeUserRef.current] = response.data.extremeValues
            return newState
        })
        console.log("SML 17")
        setMessageList(prevState => {
            const newMessages = { ...prevState };
            newMessages[activeUserRef.current] = response.data.messages;

            return newMessages;
        });


        setHighLightedMessage(invoked)


        // rollToRef.current = firstIdRef.current
        // firstIdRef.current = response.data.firstId
        // lastIdRef.current = response.data.lastId



        // setFetching(false)
        // setDownFetch(false)
        // setNowFetching(false)
    }






    // Получаем ссылку на div .message-area
    //const messageArea = document.querySelector('.message-area');


    //const messageObserver = useRef(null);

    //const [markedElements, setMarkedElements] = useState(new Set());


    //этот реф призван хранить элементы которые обсервер уже пронаблюдал в зоне видимости,
    //чтобы при добавлении не добавить ничего второй раз
    const markedElements = useRef(new Set())



    async function makeActualObserver(list) {


        console.log("MAKING OBSERVER")

        console.log(list.length)
        // Получаем ссылку на div .message-area
        const messageArea = myRef.current


        // Создаем новый IntersectionObserver и передаем ему колбэк-функцию
        // для обработки изменений видимости элементов
        const messageObserver = new IntersectionObserver((entries) => {
            // Проходимся по каждой записи в массиве entries
            entries.forEach(async (entry) => {
                // Если элемент .chat-message находится в видимой части экрана
                if (entry.isIntersecting && !markedElements.current.has(entry.target.id)) {


                    markedElements.current.add(entry.target.id);

                    // Делаем что-то с этим элементом
                    await markAsRead(entry.target.id, activeUserRef.current)
                    const myElement = document.getElementById(entry.target.id)
                    if (myElement) {
                        console.log("Выкидываем - ", myElement)
                        messageObserver.unobserve(myElement)
                    }

                }
            });
        }, {
            // Передаем ссылку на родительский элемент .message-area
            root: messageArea,
            threshold: 0.1
        });

        const chatMessages = document.querySelectorAll('.private-chat-message-left');

        //фильтруем массив элементов так, чтобы не добавлять элементы которые уже были отработаны
        const newElements = Array.from(chatMessages).filter(element => {

            const isExistingElement = markedElements.current.has(element.id);
            return !isExistingElement;
        });


        // Проходимся по каждому элементу .chat-message
        newElements.forEach((chatMessage) => {
            // Добавляем элемент в IntersectionObserver
            //console.log(chatMessage.getAttribute('data-read'))
            if (chatMessage.getAttribute('data-read') === "false") {
                console.log("Добавляем элемент - ", chatMessage)
                messageObserver.observe(chatMessage);
                //console.log("добавляем", chatMessage)
            }
        });
    }




    // useEffect(() => {
    //
    //     const messageArea = myRef.current
    //     // Создаем новый IntersectionObserver и передаем ему колбэк-функцию
    //     // для обработки изменений видимости элементов
    //     messageObserver.current = new IntersectionObserver((entries) => {
    //         // Проходимся по каждой записи в массиве entries
    //         entries.forEach(async (entry) => {
    //             // Если элемент .chat-message находится в видимой части экрана
    //
    //             if (entry.isIntersecting) {// && !markedElements.has(entry.target.id)) {
    //
    //                 setMarkedElements((prevSet) => new Set(prevSet.add(entry.target.id)));
    //
    //                 // Делаем что-то с этим элементом
    //                 const myElement = document.getElementById(entry.target.id)
    //                 //console.log(myElement)
    //                 if (myElement) {
    //                     console.log("Выкидываем - ", myElement)
    //                     await messageObserver.current.unobserve(myElement)
    //                 }
    //                 //console.log(entry.target.id, 'is visible');
    //                 console.log("MARKA 1")
    //                 await markAsRead(entry.target.id.slice(0, -3))
    //
    //
    //             }
    //         });
    //     }, {
    //         // Передаем ссылку на родительский элемент .message-area
    //         root: messageArea
    //     });
    //
    //
    //     return () => {
    //         if (messageObserver.current) {
    //             messageObserver.current.disconnect();
    //         }
    //     };
    //
    //
    // }, [])




    const [stateOfIdsOfFullUserList, setStateOfIdsOfFullUserList] = useState([])


    useEffect(() => {
        console.log("EFFECT 3")

        async function initUserListMake() {

            //let searchParams = new URLSearchParams(window.location.search);
            //let userParameter = searchParams.get('dialog');
            //let openingDialog = JSON.parse(userParameter);
            //await store.setFullUserList(store.user.fullUserList)


            if(store.messageTo !== "") {
                let addUserToFullList = true
                setActiveUser(store.messageTo)
                activeUserRef.current = store.messageTo
                console.log(toJS(store.messageTo))
                console.log(toJS(store.fullUserList))
                for(let user of store.fullUserList) {
                    if(store.messageTo.id === user.id) {
                        addUserToFullList = false
                        break
                    }
                }
                if (addUserToFullList) {
                    store.addToFullUserList(store.messageTo)
                }
                //await markAsRead(store.messageTo.id, false)
                console.log("Setting user list 1")
                await store.setPrivateUserList(store.user.privateUserList)

                const list_of_ids = store.privateUserList.map(item => item.id)

                if(list_of_ids.indexOf(store.messageTo.id) === -1) {
                    store.addToPrivateUserList(store.messageTo)
                }
                console.log("SETTING USERLIST 1")

                console.log(toJS(store.privateUserList))

                await setUserList(prev => store.privateUserList)

                store.setMessageTo("")

            } else {
                console.log("Setting user list 12")
                await store.setPrivateUserList(store.user.privateUserList)

                console.log("SETTING USERLIST 2")
                console.log(toJS(store.privateUserList))
                //await setUserList(store.user.privateUserList)


                for(let sender of store.user.privateUserList) {
                    const isSenderInList = userList.some(user => user.id === sender?.id);

                    //console.log("SETTING USER LIST 3")
                    //console.log(response.data.sendersToAdd)
                    if (!isSenderInList) {
                        await setUserList(prev => [sender, ...prev])
                  //      await store.addToFullUserList(sender)
                    }
                }


            }

            //всё что ниже создаёт лист айдишников который используется для сравнения при рендере кнопок юзеров для пересылки
            //сообщений, если юзер в листе есть то кнопка закрытия не будет прорисована, чтобы можно было убирать из листа пересылки
            //только людей, чьи диалоги уже закрыты
            let fullUserIdList = []
            for(let user of store.privateUserList) {
                fullUserIdList.push(user.id)
            }

            setStateOfIdsOfFullUserList(fullUserIdList)


        }

        initUserListMake()


        const handlePressEsc = (event) => {
            if (event.code === 'Escape') {
                cancelEditing();
                cancelAnswering();
                stopMessageSearch()
                setMarkedMessages([])
                setForwardingChatList([])
                setForwardingList([])
                setDisableForward(false)
            }
        };

        document.addEventListener('keydown', handlePressEsc);

        return () => {
            document.removeEventListener('keydown', handlePressEsc);
        };


    }, [])

    const [userListToRoll, setUserListToRoll] = useState([])
    const [fullUserListToRoll, setFullUserListToRoll] = useState([])

    const [forwardingList, setForwardingList] = useState([])
    const [forwardingMessage, setForwardingMessage] = useState()


    async function removeFromFullUserList(user_id) {
        await store.removeFromFullUserList(user_id)
        await $api.post('/full_user_list_action', {add: false, userToAction: user_id, my_id: store.user.id})
    }


    function renderFullUserList() {
        console.log("rendering FULL LIST")
        console.log(toJS(store.fullUserList))
        console.log(forwardingList)
        const fullUserListState =
            store.fullUserList.map(user => {

                return (

                    <div
                        key={user?.id + "full"}
                        className={`private-user-button-container ${forwardingList.includes(user?.id) ? "blue-theme-back" : ""}`}
                        onClick={() => {
                            console.log("CLICKs!!")
                            setForwardingList(prev => {
                                const newForwardingState = [...prev]
                                if(newForwardingState.includes(user?.id)) {
                                    const superNewState = newForwardingState.filter(item => item !== user?.id)
                                    return superNewState
                                } else {
                                    newForwardingState.push(user?.id)
                                    return newForwardingState
                                }

                            })
                        }}
                    >
                        <div className={"dot-container-private"}>
                          <img src={SvgWhiteDot} width={"15px"}/>
                        </div>
                        <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                        <div className={"width-100p"}>
                            <p className={"p-user-private-button bold"}>{user.username}</p>
                            {/*<p className={"p-user-private-button"}>некое сообщзение</p>*/}
                        </div>
                        <div className={"cross-container-private"}>
                            {stateOfIdsOfFullUserList.includes(user.id)
                                ?
                                null
                                :
                                <img
                                    className={"red-cross-private"}
                                    src={SvgRedCross} width={"15px"}
                                    onClick={() => removeFromFullUserList(user.id)}
                                    title={"Скрыть диалог"}
                                />
                            }


                        </div>

                    </div>


                    // <div key={user?.id + "full"} className={"private-user-button-container"}>
                    //
                    //     <button
                    //
                    //         className={`btn ${forwardingList.includes(user?.id) ? "btn-primary" : "btn-secondary"} private-message-contact-button ${stateOfIdsOfFullUserList.includes(user.id) ? "all-corners" : null}`}
                    //         onClick={() => {
                    //             console.log("CLICKs!!")
                    //             setForwardingList(prev => {
                    //                 const newForwardingState = [...prev]
                    //                 if(newForwardingState.includes(user?.id)) {
                    //                     const superNewState = newForwardingState.filter(item => item !== user?.id)
                    //                     return superNewState
                    //                 } else {
                    //                     newForwardingState.push(user?.id)
                    //                     return newForwardingState
                    //                 }
                    //
                    //             })
                    //         }}
                    //     > {user.username} </button>
                    //     {stateOfIdsOfFullUserList.includes(user.id)
                    //         ?
                    //         null
                    //         :
                    //         <button
                    //             className="btn btn-outline-secondary delete-private-contact-button"
                    //             onClick={() => removeFromFullUserList(user.id)}
                    //         >❌</button>
                    //     }
                    //
                    // </div>

                )
            })

        setFullUserListToRoll(fullUserListState)

    }


    function renderUserList(lastMessagesText, sortedUsersIds) {

        // for(let user of userList) {
        //     console.log(toJS(user))
        // }

        const userListToMap = userList.sort((a, b) => {

            // const keyA = (a.sender !== store.user.id) ? a.sender : a.receiver;
            // const keyB = (b.sender !== store.user.id) ? b.sender : b.receiver;
            return sortedUsersIds.indexOf(a.id) - sortedUsersIds.indexOf(b.id);
        });

        for(let user of userListToMap) {
            console.log(toJS(user))
        }
        console.log("________________")

        const userListState =
            userListToMap.map(user => {
                console.log(toJS(user))
                console.log(lastMessagesText[user.id]?.text)
                return (
                    <div
                        key={user.id}
                        className={`private-user-button-container ${activeUser?.id === user?.id ? "blue-theme-back" : ""}`}
                        onClick={() => {
                            console.log("SET 10")
                            setActiveUser(user)
                            activeUserRef.current = user
                            console.log("MARKA 2")
                            //markAsRead("all", user?.id)
                        }}
                    >
                        <div className={"dot-container-private"}>
                            {unreadMessages[user.id]?.length && activeUser?.id !== user.id
                                ?
                                <img src={SvgRedDot} width={"15px"}/>
                                :
                                <img src={SvgWhiteDot} width={"15px"}/>
                            }
                            {/*{unreadUsers.includes(user.id) && activeUser?.id !== user.id*/}
                            {/*    ?*/}
                            {/*    <img src={SvgRedDot} width={"15px"}/>*/}
                            {/*    :*/}
                            {/*    <img src={SvgWhiteDot} width={"15px"}/>*/}
                            {/*}*/}

                        </div>
                        <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                        <div className={"width-100p"}>
                            <p className={"p-user-private-button bold"}>{user.username}</p>
                            <p className={"p-user-private-button"}>{lastMessagesText[user.id] ? lastMessagesText[user.id]?.text : "..."}</p>
                        </div>
                        <div className={"cross-container-private"}>
                            <img
                                className={"red-cross-private"}
                                src={SvgRedCross} width={"15px"}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    removeUserFromList(user)
                                }}
                                title={"Скрыть диалог"}
                            />
                            <img
                                className={"dont-drag private-sound-toggler pointer"}
                                src={localStorage.getItem(`sound_private_${user?.id}`) === "true" ? SvgSoundOn : SvgSoundOff}
                                alt={localStorage.getItem(`sound_private_${user?.id}`) === "true" ? "Отключить звук" : "Включить звук"}
                                title={localStorage.getItem(`sound_private_${user?.id}`) === "true" ? "Отключить звук" : "Включить звук"}
                                width={"15px"}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if(localStorage.getItem(`sound_private_${user?.id}`) !== "true") {
                                        console.log("PLAY 3")
                                        playSound()
                                    }
                                    localStorage.setItem(`sound_private_${user?.id}`, !soundOn);

                                    setSoundOn(prev =>!prev)
                                }}
                            />
                        </div>

                        {/*<div className={"private-notify-sign ubuntu"}>{unreadUsers.includes(user.id) && activeUser?.id !== user.id*/}
                        {/*    ?*/}
                        {/*    "✉"*/}
                        {/*    :*/}
                        {/*    null*/}

                        {/*}</div>*/}
                        {/*<button*/}
                        {/*    key={user?.id}*/}
                        {/*    className={`btn ${activeUser?.id === user?.id ? "btn-primary" : "btn-secondary" } private-message-contact-button`}*/}
                        {/*    onClick={() => {*/}
                        {/*        console.log("SET 10")*/}
                        {/*        setActiveUser(user)*/}
                        {/*        activeUserRef.current = user*/}
                        {/*        console.log("MARKA 2")*/}
                        {/*        //markAsRead("all", user?.id)*/}
                        {/*    }}*/}
                        {/*> {user.username} {activeUser?.id === user?.id ? "✓" : null }</button>*/}
                        {/*<button*/}
                        {/*    className="btn btn-outline-secondary delete-private-contact-button"*/}
                        {/*    onClick={() => removeUserFromList(user?.id)}*/}
                        {/*>❌</button>*/}
                    </div>

                )
            })


        console.log(userListState)

        setUserListToRoll(userListState)

    }




    useEffect(() => {

        console.log(lastMessagesText)
        console.log("Render UserList Effect")
        console.log(toJS(userList))
        renderUserList(lastMessagesText, sortedUsersIds)



    //в этом массиве зависимостей когда то был activeUser, я его убрал за ненадобностью, вроде ничего не упало, но на всякий случай коммент
        //а потом внезапно кнопки перестали подсвечиваться через полгода, и я его вернул
    }, [userList, unreadMessages, activeUser, soundOn, lastMessagesText, sortedUsersIds])


    useEffect(() => {
        console.log("SOMTHING CHANGEDs")
        renderFullUserList()
    }, [store.fullUserList, forwardingList, stateOfIdsOfFullUserList])




    const [stopGettingPrevMessages, setStopGettingPrevMessages] = useState(false)
    const [stopGettingNextMessages, setStopGettingNextMessages] = useState(false)



    const [slicer, setSlicer] = useState(100)


    function reduceLastMessage(startingObject) {
        const lastMessagesState =  Object.entries(startingObject).reduce((acc, item) => {
            console.log(item)
            acc[item[0]] = item[1][0]
            return acc
        }, {})

        console.log(lastMessagesState)
        setLastMessagesText(lastMessagesState)

    }


    function makeSortedIds(messageList) {

        //const processingMessagesObject = {...messageList}

        const processingMessagesObject = {}

        //здесьмы дергаем самые свежие сообщения у каждого юзера,
        //для последующей сортировки юзерлиста по времени этих сообщений, ранее я копировал объект с сообщениями
        //с помощью спред опертора, но это вносило изменения в объект messageList вызывая неадекватное отображение
        //сообщений на странице, и чтобы понять откуда растут ногит у этой проблемы я блать потратил ДВА ЕБАНЫХ ДНЯ!!!
        for(let key in messageList) {
            if(messageList[key][0]) {
                processingMessagesObject[key] = [messageList[key][0]]

            }

        }

        // Object.keys(temporaryMessageStore.current).forEach(key => {
        //     if (processingMessagesObject[key]) {
        //         processingMessagesObject[key].unshift(...temporaryMessageStore.current[key]);
        //     }
        // })
        Object.keys(tempStorage).forEach(key => {
            if (processingMessagesObject[key]) {
                console.log(processingMessagesObject[key])
                console.log(tempStorage[key])
                processingMessagesObject[key].unshift(...tempStorage[key]);
            }
        })

        const processingValues = Object.values(processingMessagesObject);

        // Создаем результирующий массив, содержащий нулевые элементы из каждого массива
        const mergedArray = [];


        console.log(processingMessagesObject)
        console.log(processingValues)

        // Добавляем нулевые (всмысле первые) элементы из массива incomingValues
        processingValues.forEach(array => {
            if (array.length > 0) {
                mergedArray.push(array[0]);
            }
        });

        const sortedMergedArray = mergedArray.sort((a, b) => new Date(b.date) - new Date(a.date));

        const sortedUsersNumbers = sortedMergedArray.map(message => {
            if(message.sender !== store.user.id) {
                return message.sender
            } else {
                return message.receiver
            }

        })

        console.log(sortedUsersNumbers)

        setSortedUsersIds(sortedUsersNumbers)

    }


    async function getMinimumMessages(idList, need_unread) {

        console.log("ARMED FUNCTION 2")

        setUsersForPrevFetching(idList)


        const response = await $api.post('/get_minimum_messages', {my_id: store.user.id, senders: idList, need_unread: need_unread})
        console.log(response.data)
        setStopGettingMinimum(true)
        console.log("HERES JOHNNY")
        reduceLastMessage(response.data.messagesBySender)

        if (response.data.sendersToAdd.length) {

            const sendersToAdd = response.data.sendersToAdd;

            setUserList(prev => {
                const updatedList = [...prev];

                sendersToAdd.forEach(sender => {
                    const isSenderInList = prev.some(user => user.id === sender?.id);

                    if (!isSenderInList) {
                        updatedList.unshift(sender); // добавление в начало массива
                        store.addToFullUserList(sender);
                    }
                });

                return updatedList;
            });

            // for(let sender of response.data.sendersToAdd) {
            //     const isSenderInList = userList.some(user => user.id === sender?.id);
            //
            //     console.log("SETTING USER LIST 3")
            //     console.log(response.data.sendersToAdd)
            //     if (!isSenderInList) {
            //         await setUserList(prev => [sender, ...prev])
            //         await store.addToFullUserList(sender)
            //     }
            // }
        }

        console.log("SML 1")
        // await setMessageList(prev => ({...prev, ...response.data.messagesBySender}))
        console.log(response.data.messagesBySender)
        await setMessageList(response.data.messagesBySender)

        console.log("setting this to EXTREME VALUES")
        console.log(response.data.extremeValues)
        await setExtremeValues(response.data.extremeValues)

        extremeValuesRef.current = response.data.extremeValues
        const tempExtValues = response.data.extremeValues

        const newListForPrev = Object.keys(tempExtValues).filter(item => tempExtValues[item].firstId !== undefined)

        console.log(newListForPrev)
        setUsersForNextFetching(newListForPrev)




        const gettedDats = response.data.messagesBySender
        let unreadInfo = []

        for (let user in gettedDats) {
            console.log(user)
            for (let message of gettedDats[user]) {
                if(!message.read && message.sender !== store.user.id && user !== activeUser?.id) {

                    unreadInfo.push(user)
                    break;
                }
            }
        }


        console.log("UNREAD 1")
        console.log(unreadInfo)
        // setUnreadUsers(unreadInfo)
        setUnreadMessages(response.data.unreadMessagesBySender)

        //setStopGettingPrevMessages(true)

    }


    const [stopGettingMinimum, setStopGettingMinimum] = useState(false)

    useEffect(() => {

        console.log("EFFECT 5")

        if(userList.length >= 0 && !stopGettingMinimum) {
            console.log(toJS(userList))
            const userListIds = userList.reduce((acc, item) => {
                acc.push(item.id)
                return acc
            }, [])

            console.log(userListIds)



            getMinimumMessages(userListIds)



        }


    }, [userList, stopGettingMinimum])


    const [userListForSearch, setUserListForSearch] = useState([])
    const [searchUserValue, setSearchUserValue] = useState("")
    const [showSearch, setShowSearch] = useState(false)

    // async function getAllUsers() {
    //     const response = await $api.get('/get_users')
    //     console.log(response.data)
    //     //setUserListForSearch(response.data)
    // }


    // useEffect(() => {
    //     //getAllUsers()
    // }, [])


    const [nowUserSearching, setNowUserSearching] = useState(false)

    async function getSearchingUsers(value) {
        setNowUserSearching(true)
        const response = await $api.get(`/search_users?value=${value}&my_id=${store.user.id}`)
        setUserListForSearch(response.data)
        setNowUserSearching(false)
    }

    const userSearchTimer = useRef()

    useEffect(() => {

        clearTimeout(userSearchTimer.current)
        if(searchUserValue) {

            userSearchTimer.current = setTimeout(() => {getSearchingUsers(searchUserValue)}, 1000)
            setShowSearch(true)

        } else {
            setUserListForSearch([])
            setShowSearch(false)
        }
    }, [searchUserValue])


    // useEffect(() => {
    //
    //     console.log("EFFECT 6")
    //     async function getMessages() {
    //
    //         const response = await $api.get(`/get_user_private_messages/${store.user.id}`)
    //         setMessageList(response.data.messages)
    //
    //         const unreadInfo = response.data.messages.reduce((acc, item) => {
    //
    //
    //             if(!item.read && item.sender !== store.user.id) {
    //                 if(acc[item.sender]) {
    //                     acc[item.sender]++
    //                 } else {
    //                     acc[item.sender] = 1
    //                 }
    //             }
    //
    //             return acc
    //
    //         }, {})
    //
    //         console.log("UNREAD 2")
    //         setUnreadUsers(prev => Object.keys(unreadInfo))
    //
    //     }
    //
    //     //getMessages()
    //
    //
    // }, [])


    async function addNewUserAndHisMessagesToLists(user, receiverId) {
        console.log(user)
        const response = await $api.post(`/get_short_user_info_and_add/`, {sender_id: user, my_id: store.user.id, add: true})

        console.log(response.data)
        const username = response.data.shortInfo.name + " " + response.data.shortInfo.lastName
        console.log("Setting user list 3")

        //НУЖНО ЕЩЕ ПРИТАЩИТЬ СООБЩЕНИЯ ЭТОГО ЮЗЕРА И ДОБАВИТЬ В СТЕЙТ!!!!!!!!!
        console.log(response.data.messages.messages)

        console.log("SML 2")
        setMessageList(prevState => {
            const newMessages = { ...prevState };
            const sender = user === store.user.id ? receiverId : user
            newMessages[sender] = response.data.messages.messages;

            return newMessages;
        });

        setExtremeValues(prevState => {
            const newExtremeValues = {...prevState}
            newExtremeValues[user] = response.data.messages.extremeValues

            return newExtremeValues
        })


        setLastMessagesText(prev => {
            return {
                ...prev,
                [user]: response.data.messages.messages[0]
            }
        })

        //добавляем в юзерлист
        //console.log("SETTING USERLIST 4")
        console.log(toJS(userList))
        console.log({id: user, username: username})
        //await setUserList(prev => [{id: user, username: username}, ...prev])
        await setStateOfIdsOfFullUserList(prev => [...prev, user])
    }

    const [effectCounter, setEffectCounter] = useState(0)

    const [refreshSortedIds, setRefreshSortedIds] = useState(0)




    //при поступлении нового сообщения...
    async function newMessageHandler(newPrivateMessage) {


        stopMakingObserver.current = false
        console.log('NEW MESAGE HANDLER ARMED')

        //...добавляем сообщение в главный массив сообщений
        const message = newPrivateMessage
        console.log(message)

        if (message.forwarded?.length && typeof message.forwarded[0] === 'string') {
            console.log("НАДОБЫ ПРИТАЩИТЬ СООБЩЕНИЯ")
            const response = await $api.post('/get_forwarded_messages', {arrayOfIds: message.forwarded, purpose: "native"})
            message.forwarded = response.data
        }

        if (message.forwarded_disput?.length && typeof message.forwarded_disput[0] === 'string') {
            console.log("НАДОБЫ ПРИТАЩИТЬ СООБЩЕНИЯ")
            const response = await $api.post('/get_forwarded_messages', {arrayOfIds: message.forwarded_disput, purpose: "disput"})
            console.log(response.data)
            message.forwarded = response.data
            message.forwarded_disput = []
        }



        if(message.sender === activeUser?.id || message.sender === store.user.id) {
        // if(true) {
            console.log("FROM ACTIVE OR SELF")

            if(message.sender === store.user.id && extremeValues[activeUserRef.current].firstId) {
                console.log("AS A DOWN ARROW")
                downArrowAction()
            } else {
                console.log("SML 11")
                setMessageList(prevState => {
                    const newMessages = { ...prevState };
                    const sender = message.sender === store.user.id ? message.receiver : message.sender
                    if (newMessages[sender]) {
                        console.log(newMessages[sender])
                        console.log(message)
                        newMessages[sender].unshift(message);
                    } else {
                        newMessages[sender] = [message];
                    }
                    return newMessages;
                });



                if(message.sender !== store.user.id && soundOn) {
                    console.log("PLAY 1")
                    playSound()
                }



            }



        } else {
            console.log("Это сообщение идет во временное хранилище")
            setTempStorage(prevState => {
                const updatedStorage = { ...prevState };

                if (updatedStorage[message.sender]) {
                    console.log("Этот юзер уже есть в хранилище")
                    updatedStorage[message.sender].unshift(message);
                } else {
                    console.log("Юзера в хранилище еще не было")
                    updatedStorage[message.sender] = [message];
                }

                console.log("Хранилище тепрь выглядит так")
                console.log(updatedStorage)
                return updatedStorage;
            });
            // if(temporaryMessageStore.current.hasOwnProperty(message.sender)) {
            //
            //     console.log("ИФ АНШИФТ")
            //     console.log(temporaryMessageStore.current[message.sender])
            //     temporaryMessageStore.current[message.sender].unshift(message)
            //     console.log(temporaryMessageStore.current[message.sender])
            //
            // } else {
            //     console.log("ЭЛЗ ХУЕЛЗ")
            //     console.log(temporaryMessageStore.current[message.sender])
            //     temporaryMessageStore.current[message.sender] = [message]
            //     console.log(temporaryMessageStore.current[message.sender])
            //
            // }

            setRefreshSortedIds(prev => prev + 1)

            if(message.sender !== store.user.id && soundOn) {
                console.log('PLAY 2')
                //playSound()
            }

        }




        if(message.sender !== store.user.id
            //&& !unreadUsers.includes(message.sender)
        //    && message.sender !== activeUser?.id
        ) {
            console.log(toJS(activeUser))
            console.log("UNREAD 3")
            // console.log(unreadUsers)
            //setUnreadUsers(prev => [...prev, message.sender])
            setUnreadMessages(prev => {
                const newState = { ...prev };
                if (!newState.hasOwnProperty(message.sender)) {
                    newState[message.sender] = []; // Инициализируем как массив, если не существует
                }
                newState[message.sender].push(message._id); // Добавляем _id в массив

                return newState;
            });
        }

        //если отправитель сообщения не текущий пользователь и сообщение вообще существует, то...
        if(message?.sender !== store.user.id && message) {

            //получаем уникальный массив айдишников юзерлиста
            const uniqueById = toJS(userList).reduce((acc, current) => {
                acc.add(current.id);
                return acc;
            }, new Set());

            //разворачиваем его из сета в массив
            const IDS = [...uniqueById]
            console.log(toJS(store.privateUserList))
            //если отправителя нет в этом листе, то...

            console.log("Айдишник НЕ в списке? - ", IDS.indexOf(message.sender) === -1)

            if(IDS.indexOf(message.sender) === -1) {
                //получаем инфу о нем
                console.log(toJS(store.privateUserList))
                //ЕБУЧАЯ СТРОКА ниже этого коммента вызывала повторное добавление юзера в лист, и я до сих пор не понимаю почему
                //так происходило
                //store.addToPrivateUserList({id: message.sender, username: message.username})
                console.log("SETTING uSER LIST 4")
                setUserList(prev => [{id: message.sender, username: message.username}, ...prev])
                await addNewUserAndHisMessagesToLists(message.sender, message.receiver)

            }

            let addUserToFullList = true

            for (let user of store.fullUserList) {
                if(user.id === message.sender) {
                    addUserToFullList = false
                    break
                }
            }

            if(addUserToFullList) {
                store.addToFullUserList({id: message.sender, username: message.username})
                //await $api.post('/full_user_list_action', {add: true, userToAction: {id: message.sender, username: message.username}, my_id: store.user.id})
            }



        }

        if(message.sender === activeUser?.id) {
            console.log("сообщение от активного юзера")
            setTimeout(() => {
                if(doScroll.current) {
                    console.log("МОТАЕМ ПО НОВОМУ СООБЩЕНИЮ")
                    console.log('1 scroll')
                    myRef.current.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                    //console.log("MARKA 3")
                    //markAsRead(activeUser?.id, false)
                }
            }, 200)

        } else {
            if(message.sender !== store.user.id) {
                console.log("COUNTER SET 1")
                await store.setPrivateUnreadCounter(store.privateUnreadCounter + 1)
                const message = {
                    to: store.user.id,
                    number: Number(store.privateUnreadCounter),
                    event: "set_private_counter"
                }
                //await notifySocket.current.send(JSON.stringify(message));
            }
        }


        console.log(newPrivateMessage.sender._id !== store.user.id)

        if(newPrivateMessage.sender !== store.user.id) {

            setLastMessagesText(prev => {
                return {
                    ...prev,
                    [newPrivateMessage.sender]: newPrivateMessage
                }
            })
        } else {

            setLastMessagesText(prev => {
                return {
                    ...prev,
                    [newPrivateMessage.receiver]: newPrivateMessage
                }
            })

        }

        console.log(temporaryMessageStore.current)

    }



    useEffect(() => {
        console.log("USER LIST CHANGED!!!")
    }, [userList])




    useEffect(() => {

        console.log("EFFECT 7")
        console.log(effectCounter, " СРАБАТЫВАНИЕ")
        setEffectCounter(effectCounter + 1)


        if(newPrivateMessage && effectCounter > 0) {


            console.log(newPrivateMessage)
            setTypersArray(prev => prev.filter(user => user.id !== newPrivateMessage.sender));
            newMessageHandler(newPrivateMessage)
        }

        return () => {
            console.log("Cleaning up effect")
        }


    }, [newPrivateMessage])


    useEffect(() => {

        console.log("EFFECT 8")


        cancelAnswering()
        cancelEditing()

        // if(activeUser?.id && !messageList[activeUser]) {
        //     getMinimumMessages([activeUser?.id])
        // }
        setGetMorePrevMessages(false)
        console.log("SETTING GET MORE NEXT 1")
        setGetMoreNextMessages(false)

        if (usersForPrevFetching.indexOf(activeUserRef.current) > -1) {
            //console.log("НАДО БЫ УДАЛИТЬ")

            //console.log("ST UNREAD LIST 1")
            //console.log("USER - ", activeUser?.id)
            //const newUnread = unreadUsers.filter(item => item !== activeUser?.id)
            //console.log(newUnread)
            //setUnreadUsers(prev => newUnread)
        }


        if(messageList[activeUser?.id]) {
            refToRoll.current = messageList[activeUser?.id][0]?._id

        }

        setTimeout(() => {
            //myRef.current.scrollTo(0, myRef.current.scrollHeight)
            console.log('2 scroll')
            myRef.current?.scrollTo({
                top: 0,
                //behavior: 'smooth'
            })

        }, 100)


    }, [activeUser])



    async function markAsRead(message_id, user_id) {

        console.log(message_id)
        console.log(`MARKING ${message_id === "all" ? message_id : "ONE"} AS READ`)

        if(message_id === "all") {

            console.log("ST UNREAD LIST 2")

            // console.log(unreadUsers)
            // const newUnread = unreadUsers.filter(item => item !== user_id)
            // console.log(newUnread)
            // console.log("SUU 1")
            // setUnreadUsers(newUnread)

            setUnreadMessages(prev => {
                const newState = { ...prev };
                delete newState[user_id]; // Удалить ключ и его значение

                return newState;
            });



            const response = await $api.post('/mark_private_as_read', {
                method: "all",
                sender: user_id,
                receiver: store.user.id
            })
            console.log(response.data)

            if (store.privateUnreadCounter >= Number(response.data.value)) {
                console.log("COUNTER SET 21")
                const counter = store.privateUnreadCounter - Number(response.data.value)
                await store.setPrivateUnreadCounter(counter)
                localStorage.setItem('messageCounter', counter.toString());
                const message = {
                    to: store.user.id,
                    number: Number(store.privateUnreadCounter),
                    event: "set_private_counter"
                }
                await notifySocket.current.send(JSON.stringify(message));

            }


            if (response.status >= 400) {
                setErrorMessage({show: true, text: `сервер вернул ошибку: ${response.data.message}`})
            } else {
                console.log("ДА МЫ ПРИЕХАЛИ В ЭТОТ ЭЛЗ")
                console.log("user_id - " , user_id)
                console.log(toJS(userList))
                const newUsersForNextFetching = usersForNextFetching.filter(item => item !== user_id)
                setUsersForNextFetching(newUsersForNextFetching)

                let needToGetNewMinimumMessages = false

                const idsOfUserList = userList.map(user => user.id)

                if(!idsOfUserList.includes(user_id)) {
                    console.log("НАДО ГРУЗИТЬ")
                    console.log("ARMED FUNCTION 3")
                    const response = await $api.post('/get_minimum_messages', {my_id: store.user.id, senders: [user_id], need_unread: false})
                    console.log(response.data)


                    let newExtremeValues = {...extremeValues}
                    newExtremeValues[user_id] = response.data.extremeValues[user_id]
                    console.log("setting this to EXTREME VALUES")
                    console.log(newExtremeValues)
                    await setExtremeValues(newExtremeValues)

                    let newMessageList = {...messageList}
                    newMessageList[user_id] = response.data.messagesBySender[user_id]

                    console.log("SML 4")
                    await setMessageList(newMessageList)
                } else {
                    console.log("НЕ НАДО НИЧЕГО ГРУЗИТЬ")
                }


            }

        } else {
            //console.log("ST UNREAD LIST 3")
            //console.log(unreadUsers)
            //console.log("DELETING USER - ", activeUserRef.current)

            //const newUnread = unreadUsers.filter(item => item !== activeUserRef.current)
            //setUnreadUsers(newUnread)

            const response = await $api.post('/mark_private_as_read', {message_id: message_id})
            if (response.status >= 400) {
                setErrorMessage({show: true, text: `сервер вернул ошибку: ${response.data.message}`})

            } else {


                console.log(myRef.current.scrollTop)
                if(myRef.current.scrollTop > -150) {
                    // const newUnread = unreadUsers.filter(item => item !== user_id)
                    // console.log(user_id)
                    // console.log(newUnread)
                    // console.log("SUU 3")
                    // setUnreadUsers(newUnread)

                    setUnreadMessages(prev => {
                        const newState = { ...prev };

                        if (newState.hasOwnProperty(user_id)) {
                            if (newState.hasOwnProperty(user_id)) {
                                newState[user_id] = newState[user_id].filter(id => id !== message_id);
                            }
                        }
                        return newState;
                    });



                }


                const counter = store.privateUnreadCounter - 1
                await store.setPrivateUnreadCounter(counter)
                localStorage.setItem('messageCounter', counter.toString());
                const message = {
                    to: store.user.id,
                    number: Number(store.privateUnreadCounter),
                    event: "set_private_counter"
                }
                await notifySocket.current.send(JSON.stringify(message));
            }
        }

    }




    const [getMorePrevMessages, setGetMorePrevMessages] = useState(false)
    const [getMoreNextMessages, setGetMoreNextMessages] = useState(false)

    const handleScroll = throttle(scroller, 300)

    const rollToRef = useRef()
    const extremeValuesRef = useRef()

    const [scrollingUp, setScrollingUp] = useState(false)
    const scrollingUpRef = useRef(false)


    function scroller(e) {
        console.log("SCROLL")


        if(e.target.scrollTop < -350) {
            doScroll.current = false
        } else {
            doScroll.current = true

        }


        setTimeout(() => {
            if(e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight < 10 && extremeValuesRef.current[activeUserRef.current]?.lastId) {
                console.log("PREV FETCHING")
                setGetMorePrevMessages(true)
                setSlicer(prev => prev + 1000000)
            }
        }, 500)



        setTimeout(() => {
            if(e.target.scrollTop > -5 && extremeValuesRef.current[activeUserRef.current].firstId && !scrollingUpRef.current) {

                console.log("BLINK - НУЖНО ПОДГРУЗИТЬ СООБЩЕНИЯ СНИЗУ (БОЛЕЕ НОВЫЕ)")
                console.log(extremeValuesRef.current[activeUserRef.current].firstId)
                setScrollingUp(true)
                scrollingUpRef.current = true
                setGetMoreNextMessages(true)

            }
        }, 500)




        if (e.target.scrollTop < -150) {
            setShowArrowDown(true)
        }

        if (e.target.scrollTop > -10) { //&& stopDownFetchingRef.current) {
            setShowArrowDown(false)
        }

        if (e.target.scrollTop > -1) {


            //console.log("SETTING SLICER TO 100")
            //setSlicer(100)

            setTimeout(() => {

                // console.log(extremeValues)
                // console.log(getMoreNextMessages, ' - getMoreNextMessages')
                console.log("SETTING GET MORE NEXT 2")
                //setGetMoreNextMessages(true)


            }, 500)

        }



        // console.log(e.target.scrollHeight)
        // console.log(e.target.scrollTop)
        // console.log(e.target.clientHeight)
        // console.log(e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight)
    }


    function throttle(func, delay) {
        let timerId;
        let lastExecTime = 0;

        return function (...args) {
            const currentTime = Date.now();

            if (currentTime - lastExecTime > delay) {
                func.apply(this, args);
                lastExecTime = currentTime;
            } else {
                clearTimeout(timerId);
                timerId = setTimeout(() => {
                    func.apply(this, args);
                    lastExecTime = currentTime;
                }, delay);
            }
        };
    }





    const scrollRef = useRef(0)

    useEffect(() => {
        console.log("CURRENT TRIGGER")
        console.log(extremeValues)

        console.log(extremeValues[activeUserRef.current])


        if(getMorePrevMessages && usersForPrevFetching.indexOf(activeUserRef.current) > -1) {

            //getAnother100Messages(extremeValues[toJS(activeUser).id]?.lastId, null)
            getAnother100Messages(extremeValues[activeUserRef.current]?.lastId, null)

        }
    }, [getMorePrevMessages])



    const [nextFetching, setNextFetching] = useState(false)


    useEffect(() => {


        console.log("BLINK - ЗАПУЩЕН ЭФФЕКТ ПОДГРУЗКИ НИЖНИХ СООБЩЕНИЙ")

        //if(!nextFetching && getMoreNextMessages && usersForNextFetching.includes(activeUserRef.current)) {
        if(!nextFetching && getMoreNextMessages) {
            console.log("BLINK - ЗАПУСКАЕМ ФУНКЦИЮ ПОДГРУЗКИ НИЖНИХ СООБЩЕНИЙ")
            console.log("WITH FIRST ID - ", extremeValues[activeUserRef.current]?.firstId)
            setNextFetching(true)
            getAnother100Messages(null, extremeValuesRef.current[activeUserRef.current]?.firstId)

        } else {
            console.log("BLINK - ФУНКЦИЯ ЗАГРУЗКИ СООБЩЕНИЙ НЕ ЗАПУЩЕНА, НЕ ВЫПОЛНЕНЫ УСЛОВИЯ")
            console.log("!nextFetching - ", !nextFetching)
            console.log("getMoreNextMessages - ", getMoreNextMessages)
        }

    }, [getMoreNextMessages, nextFetching])



    const refToRoll = useRef()

    useEffect(() => {

        console.log(messagesToRoll)



        async function rollAndObserve() {


            console.log("ROLL AND OBSERVE STARTED")

            if(scrollToInvokeRef.current) {

                console.log("scrollToInvokeRef detected")

                //setTimeout(() => {



                const targetElement = document.getElementById(invokedMessageIdRef.current);
                const scrollContainer = myRef.current;

                if (targetElement && scrollContainer) {
                    const targetElementY = targetElement.offsetTop;
                    const scrollContainerHeight = scrollContainer.clientHeight;

                    scrollContainer.scrollTop = targetElementY - scrollContainerHeight / 1.5;
                    invokedMessageIdRef.current = null
                    scrollToInvokeRef.current = false

                    // Дополнительно, если вы хотите добавить плавность, вы можете использовать анимацию
                    // scrollContainer.scrollTo({
                    //     top: targetElementY,
                    //     behavior: 'smooth',
                    // });
                }



                // const targetElement = document.getElementById(invokedMessageIdRef.current);
                //
                // console.log(invokedMessageIdRef.current)
                // console.log(targetElement)
                //
                // if (targetElement) {
                //     targetElement.scrollIntoView({
                //         block: 'center',
                //     });
                //     invokedMessageIdRef.current = null
                //     scrollToInvokeRef.current = false
                // }
                //}, 2000)

                //stopDownFetchingRef.current = false
                //stopFetching.current = false



            } else {

                console.log("ROLL AND OBSERVE IS NOT STARTED")

                if(myRef.current.querySelector('.new-message-line')) {

                    console.log("NEW LINE DETECTED")
                    console.log('5 scroll')
                    await myRef.current.scrollTo({
                        top: myRef.current.querySelector('.new-message-line').offsetTop - 150, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                        //behavior: 'smooth', // Прокручиваем с анимацией
                    })

                } else {

                    console.log("NEW LINE IS NOT DETECTED")
                    if(myRef.current?.scrollTop > -100 && extremeValuesRef.current[activeUserRef.current]?.firstId) {

                        console.log("LAST SCROLL TO ACTIVATED")

                        const element = document.getElementById(rollToRef.current)

                        console.log(rollToRef.current)
                        console.log(element)

                        console.log("scroll to 999")
                        await myRef.current.scrollTo({
                            top: element?.offsetTop - (myRef.current.offsetHeight + 35), // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                            //behavior: 'smooth', // Прокручиваем с анимацией
                        })
                        setScrollingUp(false)
                        scrollingUpRef.current = false
                    } else {
                        console.log("EMPTY BLOCK")
                        console.log(rollToRef.current)
                        if(rollToRef.current) {
                            // const element = document.getElementById(rollToRef.current)
                            //
                            // console.log(rollToRef.current)
                            // console.log(element)
                            //
                            // console.log("scroll to 998")
                            //
                            //
                            //
                            // myRef.current.scrollTo({
                            //     top: element?.offsetTop - (myRef.current.offsetHeight + 30), // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                            //     //behavior: 'smooth', // Прокручиваем с анимацией
                            // })

                            rollToRef.current = null
                        }
                        scrollingUpRef.current = false


                    }

                }
            }



            if(!stopMakingObserver.current) {
                setTimeout(() => {
                    makeActualObserver(messagesToRoll)
                }, 1000)

            }


        }


        rollAndObserve()

        console.log(myRef.current.scrollTop)




        // const element = document.getElementById(refToRoll.current)
        // console.log("SCROLL TO 2")
        // console.log(element)

        if (nextFetching) {
            // console.log('3 scroll')
            // myRef.current.scrollTo({
            //     top: element?.offsetTop - (myRef.current.offsetHeight + 65), // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
            //     //behavior: 'smooth', // Прокручиваем с анимацией
            // })
            //
            // if(messageList[activeUser?.id]) {
            //
            //     refToRoll.current = messageList[toJS(activeUser).id][0]._id
            // }

            setNextFetching(false)
        }






        // setTimeout(() => {
        //     if(messagesToRoll?.length) {
        //         // Получаем все элементы .chat-message
        //
        //         const chatMessages = document.querySelectorAll('.private-chat-message-left');
        //         //setAddFirstMessages(false)
        //
        //         const newElements = Array.from(chatMessages).filter((element) => {
        //             const isExistingElement = markedElements.has(element.id);
        //             return !isExistingElement;
        //         });
        //
        //
        //         // Проходимся по каждому элементу .private-chat-message-left
        //         newElements.forEach((chatMessage) => {
        //             // Добавляем элемент в IntersectionObserver
        //             if (chatMessage.getAttribute('data-read') === "false") {
        //                 messageObserver.current.observe(chatMessage);
        //                 console.log(chatMessage)
        //                 //console.log("добавляем", chatMessage)
        //             }
        //         });
        //     }
        // }, 2000)




    }, [messagesToRoll])



    async function getAnother100Messages(last, first) {

        console.log("BLINK - ФУНКЦИЯ ЗАГРУЗКИ СООБЕНИЙ ЗАПУЩЕНА")

        scrollRef.current = myRef.current.scrollHeight


        console.log("GETTING 100")
        console.log(last, ' - last')
        console.log(first, ' - first')


        if (last) {

            console.log("BLINK - В БЛОКЕ ВЕРХНИХ СООБЩЕНИЙ")
            console.log("ARMED FUNCTION 4")
            const response = await $api.get(`/get_100_messages?my_id=${store.user.id}&sender=${activeUserRef.current}&lastId=${last}`)
            console.log(response.data)
            console.log("SML 5")
            await setMessageList(prevState => {
                const newMessages = { ...prevState };
                console.log(prevState)
                newMessages[activeUserRef.current] = [...newMessages[toJS(activeUser).id], ...response.data.messages];

                return {...newMessages};
            });





            if (response.data.extremeValues.lastId) {
                console.log("setting this to EXTREME VALUES 3")

                await setExtremeValues(prevState => {
                    const newExtremeValues = {...prevState};
                    newExtremeValues[toJS(activeUser).id].lastId = response.data.extremeValues.lastId;

                    return {...newExtremeValues}

                })

                //extremeValuesRef.current[activeUserRef.current].firstId = response.data.extremeValues.lastId

            } else {

                extremeValuesRef.current[activeUserRef.current].lastId = undefined
                setUsersForPrevFetching(prevState => {
                    let newState = [...prevState]
                    const index = newState.indexOf(activeUserRef.current)
                    newState.splice(index, 1)

                    return newState


                })
                //setStopGettingPrevMessages(true)
            }

        }


        if (first) {

            console.log("BLINK - В БЛОКЕ НИЖНИХ СООБЩЕНИЙ")

            console.log("ARMED FUNCTION 5")
            const response = await $api.get(`/get_100_messages?my_id=${store.user.id}&sender=${activeUserRef.current}&firstId=${first}`)

            console.log("BLINK - РЕЗУЛЬТАТЫ ЗАПРОСА НИЖНИХ СООБЩЕНИЙ")
            console.log(response.data)



            console.log(rollToRef.current)

            console.log("SML 6")
            await setMessageList(prevState => {

                const newMessages = { ...prevState };
                //console.log("ROLL TO REF CHANGING 4")
                //rollToRef.current = newMessages[activeUserRef.current][0]._id
                console.log((document.getElementById(newMessages[activeUserRef.current][0]._id)))
                console.log(prevState)
                const newArray = [...response.data.messages, ...newMessages[activeUserRef.current]];

                newMessages[activeUserRef.current] = newArray

                console.log(newArray)

                for(let mess of newArray) {
                    if (mess._id === rollToRef.current) {
                        console.log(mess._id)
                        console.log('НУЖНОЕ СООБЩЕНИЕ ЕСТЬ В МАССИВЕ')
                    }
                }

                return {...newMessages};
            });

            if (response.data.extremeValues?.firstId) {

                console.log("ROLL TO REF CHANGING 3, to - ", extremeValuesRef.current[activeUserRef.current].firstId)
                rollToRef.current = extremeValuesRef.current[activeUserRef.current].firstId
                extremeValuesRef.current[activeUserRef.current].firstId = response.data.extremeValues.firstId

                console.log("setting this to EXTREME VALUES 4")
                await setExtremeValues(prevState => {
                    const newExtremeValues = {...prevState};

                    newExtremeValues[activeUserRef.current].firstId = response.data.extremeValues.firstId;

                    return {...newExtremeValues}

                })


            } else {
                console.log("ROLL TO REF CHANGING 2, to NULL")
                rollToRef.current = null
                extremeValuesRef.current[activeUserRef.current].firstId = undefined

                    setUsersForNextFetching(prevState => {
                        let newState = [...prevState]
                        const index = newState.indexOf(activeUserRef.current)
                        newState.splice(index, 1)

                        return newState


                    })

                console.log("ST UNREAD LIST 4")
                console.log("USER - ", activeUserRef.current)
                // const newUnread = unreadUsers.filter(item => item !== activeUserRef.current)
                // console.log("SUU 2")
                // setUnreadUsers(newUnread)
                //setStopGettingNextMessages(true)


                setUnreadMessages(prev => {
                    const newState = { ...prev };
                    delete newState[activeUserRef.current]; // Удалить ключ и его значение

                    return newState;
                });

                }
        }


        if(!last && !first) {

            console.log("BLINK - В БЛОКЕ МИНИМУМ")

            console.log("ARMED FUNCTION 6")
            const response = await $api.get(`/get_100_messages?my_id=${store.user.id}&sender=${activeUserRef.current}&minimum=${true}`)
            console.log(response.data)
            console.log("SML 62")
            await setMessageList(prevState => {
                const newMessages = { ...prevState };
                console.log(prevState)
                newMessages[toJS(activeUser).id] = response.data.messages

                return {...newMessages};
            });

            if (response.data.extremeValues?.lastId) {

                console.log("ROLL TO REF CHANGING 1, to NULL")
                rollToRef.current = null
                extremeValuesRef.current[activeUserRef.current].lastId = response.data.extremeValues.lastId
                extremeValuesRef.current[activeUserRef.current].firstId = undefined

                console.log("setting this to EXTREME VALUES 4")
                await setExtremeValues(prevState => {
                    const newExtremeValues = {...prevState};

                    newExtremeValues[activeUserRef.current].lastId = response.data.extremeValues.lastId;
                    newExtremeValues[activeUserRef.current].firstId = undefined;

                    return {...newExtremeValues}

                })


            }
            // else {
            //
            //     rollToRef.current = null
            //     extremeValuesRef.current[activeUserRef.current].firstId = undefined
            //
            //     setUsersForNextFetching(prevState => {
            //         let newState = [...prevState]
            //         const index = newState.indexOf(activeUserRef.current)
            //         newState.splice(index, 1)
            //
            //         return newState
            //
            //
            //     })
            //
            //     console.log("ST UNREAD LIST 4")
            //     console.log("USER - ", activeUserRef.current)
            //     const newUnread = unreadUsers.filter(item => item !== activeUserRef.current)
            //     console.log("SUU 2")
            //     setUnreadUsers(newUnread)
            //     //setStopGettingNextMessages(true)
            // }

        }



        console.log("SETTING GET MORE NEXT 3")
        if(nextFetching) {
            await setNextFetching(false)
        }
        if(getMoreNextMessages) {
            await setGetMoreNextMessages(false)
        }

        if (getMorePrevMessages) {
            await setGetMorePrevMessages(false)
        }

        console.log("BLINK - ФУНКЦИЯ ЗАГРУЗКИ СООБЩЕНИЙ ЗАВЕРШЕНА")


    }



    async function deleteAttached(path, filetype) {

        setErrorMessage({show: false, text: ""})

        if(isMessageEditing) {

            setDeleteThisFiles(prev => prev.push({path, filetype}))

            setPinnedFiles((prevState) => {
                return prevState.filter((file) => file.path !== path);
            });

        } else {
            const response = await $api.post('/delete_private_file', {path, filetype})
            if(response.status < 400) {
                const newState = pinnedFiles.filter(item => item.path !== path)
                setPinnedFiles(prev => newState)
            } else {
                setErrorMessage({show: true, text: response.data.message})
            }
        }



    }


    async function handleFiles(files) {


        setErrorMessage({show: false, text: ""})
        if(files.length > 3) {
            setErrorMessage({show: true, text: "Максимум 3 файла в сообщении"})
            return false
        }

        for(let file of files) {
            if(file.size > 5000000){
                setErrorMessage({show: true, text: "Размер файла не должен превышеть 5мб"})
                return false
            }
        }

        if(pinnedFiles.length < 3) {
            setSpinner(true)
            setTimeout(async () => {
                const response = await $api.post(`/load_private_files/${store.user.id}`, files)
                console.log(response.data)
                if(response.status < 400) {
                    setSpinner(false)
                    await setPinnedFiles(prev => [...prev, ...response.data.files])
                } else {
                    setSpinner(false)
                    setErrorMessage({show: true, text: response.data.message})
                }
            }, 3000)



        } else {
            setErrorMessage({show: true, text: "К одному сообщению можно приложить не более 3-х файлов"})
        }
        return true
    }


    const [messageSending, setMessageSending] = useState(false)


    async function sendMessage() {

        setMessageSending(true)

        let event

        if(forwardingList.length || (!forwardingList.length && !forwardingChatList.length)) {

            console.log(answeringMessage)

            if(((printedMessage !== "" || pinnedFiles.length > 0) && activeUser) || forwardingList.length) {

                const message = {
                    sender: store.user.id,
                    username: store.user.name + " " + store.user.lastName,
                    receiver: forwardingList.length ? forwardingList : activeUser.id,
                    text: printedMessage,
                    links: pinnedFiles,
                    date: Date.now(),
                    read: false,
                    need_rolling:
                        answeringMessage.quote?.quote !== undefined
                        || answeringMessage.quote?.quote_disput !== undefined
                        || answeringMessage.quote?.forwarded !== undefined
                        || answeringMessage.quote?.forwarded_disput !== undefined
                        || answeringMessage.quote_disput?.quote !== undefined
                        || answeringMessage.quote_disput?.quote_disput !== undefined
                        || answeringMessage.quote_disput?.forwarded !== undefined
                        || answeringMessage.quote_disput?.forwarded_disput !== undefined
                        || answeringMessage.forwarded_disput?.length > 0
                        || answeringMessage.forwarded?.quote !== undefined
                        || answeringMessage.forwarded?.quote_disput !== undefined
                        || answeringMessage.forwarded?.forwarded !== undefined
                        || answeringMessage.forwarded?.forwarded_disput !== undefined
                    ,
                    quote: answeringMessage.id ?? null,
                    quote_self: answeringMessage.self,
                    quote_date: answeringMessage.date,
                    quote_text: answeringMessage.text,
                    quote_links: answeringMessage.links,
                    quote_forwarded: answeringMessage.forwarded,
                    quote_forwarded_disput: answeringMessage.forwarded_disput,
                    //forwarded_array: markedMessages.map((markedMessageId) => messageList[activeUserRef.current].find((message) => message._id === markedMessageId) || null),
                    forwarded_array: markedMessages.map((markedMessageId) => {
                        const foundMessage = messageList[activeUserRef.current].find((message) => message._id === markedMessageId) || null;

                        if (foundMessage) {
                            const foundUser = userList.find((user) => user.id === activeUserRef.current) || {};
                            const isSenderCurrentUser = foundMessage.sender === store.user.id;

                            const newSender = isSenderCurrentUser
                                ? {
                                    _id: store.user.id,
                                    lastName: store.user.lastName,
                                    name: store.user.name,
                                }
                                : {
                                    _id: foundUser.id || null,
                                    name: foundUser.username || null,
                                };

                            return {
                                ...foundMessage,
                                sender: newSender,
                            };
                        } else {
                            return null;
                        }
                    }),
                    forwarded_from: markedMessages.length ? "privateMessages" : null,
                    on_fly: true,
                    event: "private_message"
                }

                console.log(message)
                const response = await $api.post('/post_private_message_to_bd', {message})

                if(response.status < 400) {
                    //раньше тут была отправка сообщения вебсокету, но я перенес ее на бэкенд для реализации
                    //функционала с удаляемыми временными файлами
                    //await notifySocket.current.send(JSON.stringify(message));
                    setPrintedMessage("")
                    setPinnedFiles([])
                    //ниже магия которая крутит окно сообщений с небольшой задержкой вниз после отправки сообщения

                    if(usersForNextFetching.indexOf(activeUserRef.current) === -1) {
                        // setTimeout(() => {
                        //     if(doScroll.current) {
                        //         console.log("МОТАЕМ ПО ОТПРАВКЕ!")
                        //         console.log('4 scroll')
                        //         myRef.current.scrollTo({
                        //             top: 0,
                        //             behavior: 'smooth'
                        //         })
                        //     }
                        //
                        // }, 200)
                    } else {
                        console.log("MARKA 4")
                        console.log("scroll to 997")
                        await markAsRead("all", activeUser.id)
                        myRef.current.scrollTo({top: 0, })
                    }


                    if(forwardingList.length) {
                        const currentIDS = toJS(userList).map(item => item.id)
                        const  usersToAdd = forwardingList.filter(item => currentIDS.indexOf(item) === -1)

                        for(let user of usersToAdd) {
                            addNewUserAndHisMessagesToLists(user)
                        }

                    }

                    console.log("MESSAGE SENT")
                    console.log(message)


                } else {
                    setErrorMessage({show: true, text: response.data.message})
                }
            }

        } else {

            console.log(toJS(forwardingChatList))

            for(let chat of forwardingChatList) {
                console.log(chat.chat_id)

                let forwardChatId = chat.chat_id

                event = `message-${chat.chat_id}`

                //if (value !== "" || pinnedFiles.length) {
                document.getElementById("chat-input").focus()
                const message = {
                    receiver: null,
                    sender: {
                        _id: store.user.id,
                        name: store.user.name,
                        lastName: store.user.lastName
                    },
                    sender_name: store.user.name + " " + store.user.lastName,
                    sender_id: store.user.id,
                    username: store.user.name + " " + store.user.lastName,
                    text: printedMessage,
                    read: [],
                    date: Date.now(),
                    need_rolling: answeringMessage.need_rolling,
                    quote_disput: answeringMessage.id ?? null,
                    quote_sender: answeringMessage.sender,
                    quote_sender_name: answeringMessage.quote_sender_name,
                    quote_self: answeringMessage.self,
                    quote_date: answeringMessage.date,
                    quote_text: answeringMessage.text,
                    quote_links: answeringMessage.links,
                    forwarded_disput: markedMessages,
                    //forwarded_array: markedMessages.map((markedMessageId) => messageList[activeUserRef.current].find((message) => message._id === markedMessageId) || null),
                    forwarded_array: markedMessages.map((markedMessageId) => {
                        const foundMessage = messageList[activeUserRef.current].find((message) => message._id === markedMessageId) || null;

                        if (foundMessage) {
                            const foundUser = userList.find((user) => user.id === activeUserRef.current) || {};
                            const isSenderCurrentUser = foundMessage.sender === store.user.id;

                            const newSender = isSenderCurrentUser
                                ? {
                                    _id: store.user.id,
                                    lastName: store.user.lastName,
                                    name: store.user.name,
                                }
                                : {
                                    _id: foundUser.id || null,
                                    name: foundUser.username || null,
                                };

                            return {
                                ...foundMessage,
                                sender: newSender,
                            };
                        } else {
                            return null;
                        }
                    }),
                    forwarded_from: "privateMessages",
                    on_fly: true,
                    // invocation: JSON.stringify(invocationTo) ?? null,
                    invocation: null,
                    event: event,
                    links: pinnedFiles,

                }

                console.log(message)

                //await socket.current.send(JSON.stringify(message));

                if (true) {
                    try {
                        const response = await $api.post("/write_message_to_bd", {message, chatId: forwardChatId})

                        console.log(response.data)
                        setPrintedMessage("")
                        setPinnedFiles([])

                        if (response.status < 400) {
                            downArrowAction()
                            console.log("Scrolling to 0")
                            // canvasRef.current.scrollTo({
                            //     top: 0,
                            //     //behavior: 'smooth'
                            // })


                        }

                    } catch {
                        console.log("чот ошибка какая то")

                        setErrorMessage("Сервер недоступен, попробуйте обновить страничку")
                    }
                }
                //}


            }

        }


        setMarkedMessages(prev => [])
        setForwardingList(prev => [])
        setForwardingChatList(prev => [])
        setDisableForward(false)
        setAnsweringMessage({text: null, id: null, date: null, self: null, links: null, forwarded: null, forwarded_disput: [], quote: null, quote_disput: null})
        document.querySelector("#user-list").classList.remove("rotated-180")
        textAreaRef.current.style.height = "auto"
        console.log("scroll to 996")
        myRef.current.scrollTo({top: 0})

        setMessageSending(false)
        window.scrollTo(0, 0)

    }


    function dateSet(date) {

        if(new Date(nowDate).toLocaleDateString() === new Date(date).toLocaleDateString()) {

            return new Date(date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        } else {

            return new Date(date).toLocaleDateString([], {month: "long", year: "numeric", day: "numeric", hour: '2-digit', minute:'2-digit'})
        }
    }


    async function downloadHandler(path) {


        const response = await $api.get(`/download_file?path=${path}`, {responseType: 'arraybuffer'})


        //создаем блоб (Binary Large OBject) из ответа
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        //const blob = await response.blob()
        //из него генерируем ссылку на файл
        const downloadURL = URL.createObjectURL(blob)
        //создаем элемент ссылки
        const link = await document.createElement('a')
        //назначаем элементу собственно ссылку
        link.href = downloadURL
        //это тоже всё еще назначение ссылки (тут добавляется имя файла)
        link.download = await path.split('\\')[5]
        //добавляем то что создали в ДОМ
        document.body.appendChild(link)
        //кликаем
        link.click()
        //и избавляемся от элемента
        link.remove()
        URL.revokeObjectURL(downloadURL)
    }


    function linkRoller(linksArray) {
        const result = []
        for(let link of linksArray) {

            let type

            if(link.type === "doc" || link.type === "docx") {
                type = "word"
            } else if (link.type === "xls" || link.type === "xlsx") {
                type = "excel"
            } else if (link.type === "ppt" || link.type === "pptx") {
                type = "power_point"
            } else if (link.type === "rar" || link.type === "zip") {
                type = "winrar"
            } else if (link.type === "pdf") {
                type = "pdf"
            } else if (link.type === "image") {
                type = "image"
            } else {
                type = "doc_thumb"
            }

            if(type === "image") {

                result.push(
                    <div
                        className={"chat-pre-image-2"}
                        onClick={() => {
                            console.log("OPENING PICTURE")
                            setActiveModalImgViewer("progress")
                            setTimeout(() => {
                                setActiveModalImgViewer("active")
                            }, 100)
                            console.log(link)
                            setImageSource(link.path)
                        }}
                    >

                        {/*<a className={"link-from-roller"} href={API_URL + "/" + link.path} target={"_blank"}>*/}
                        <img
                            className={"chat-pre-image dont-drag"}
                            src={API_URL + "/" + link.thumbnail}
                        />
                        {/*</a>*/}

                    </div>

                )
            } else {

                const filenameWindows = link.path.substring(link.path.lastIndexOf("\\") + 1).replace(/_/g, " ");
                let filename;

                if (filenameWindows.includes('/')) {
                    filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                } else {
                    filename = filenameWindows.replace(/_/g, " ");

                }

                result.push(
                    <div>

                        <img className={"order-done-file-image dont-drag"} src={require(`../img/${type}.png`)} width={"50"}/>
                        <a
                            className={"fake-link"}
                            onClick={() => downloadHandler(link.path)}
                        >{filename}</a>
                    </div>

                )
            }

        }

        return result

    }

    const [answeringMessage, setAnsweringMessage] = useState({
        text: null,
        id: null,
        date: null,
        self: null,
        links: null,
        forwarded: null,
        forwarded_disput: [],
        quote: null,
    })


    function answerOnMessage(text, id, date, self, links, forwarded, forwarded_disput, quote, quote_disput) {
        console.log("SET 2")
        document.getElementById("chat-input").focus()
        console.log(text, id, date, self, links, forwarded, forwarded_disput)
        setAnsweringMessage({text: text, id: id, date: date, self: self, links: links, forwarded: forwarded, forwarded_disput: forwarded_disput, quote: quote, quote_disput: quote_disput})

        console.log(quote?.quote)
        console.log(quote?.quote_disput)
        console.log(quote?.forwarded)
        console.log(quote?.forwarded_disput)
        console.log(quote_disput?.quote)
        console.log(quote_disput?.quote_disput)
        console.log(quote_disput?.forwarded)
        console.log(quote_disput?.forwarded_disput)
        console.log(forwarded_disput)
        console.log(forwarded?.quote)
        console.log(forwarded?.quote_disput)
        console.log(forwarded?.forwarded)
        console.log(forwarded?.forwarded_disput)


    }

    function cancelAnswering() {
        console.log("SET 3")
        setAnsweringMessage({text: null, id: null, date: null, self: null, links: null, forwarded: null, forwarded_disput: [], quote: null, quote_disput: null})
    }


    const [nestedContent, setNestedContent] = useState()
    const [stepCounter, setStepCounter] = useState(0)


    async function showNestedMessage(id, chatId, stepBack, marker) {
        console.log(id)
        console.log(chatId)
        console.log(stepBack)
        //const chatId = "privateMessages"
        console.log(marker, " - marker")
        const response = await $api.get(`/get_nested_message?message_id=${id}&chat_id=${chatId}`)
        console.log(response.data)
        await setNestedContent(response.data)
        if (stepBack) {
            console.log("step back")
            console.log(`Setting step counter to ${stepCounter - 1}`)
            setStepCounter(stepCounter - 1)
            setLastSteps(prev => prev.slice(0, -1));
        } else {
            console.log("step forward")
            console.log(`Setting step counter to ${stepCounter + 1}`)
            setStepCounter(stepCounter + 1)
            setLastSteps(prev => [...prev, id])
        }

        setActiveMessageModal("progress")
        setTimeout(() => {
            setActiveMessageModal("active")
        }, 100)
    }






    const renderForwardedMessages = (list, key_id, from, on_fly) => {

        console.log(list)
        console.log(from)
        console.log(toJS(store.chatList))

        const sortedList = list.sort((a, b) => {
            if (a._id < b._id) {
                return -1;
            }
            if (a._id > b._id) {
                return 1;
            }
            return 0;
        });

        const mappedArray = sortedList.map((message, index) => {

            console.log(message)
            //если сообщение не ... то нужно запросить по нему информацию

            return (
                <div key={message._id + key_id} className={"forwarded-message"}>
                    <h6 className={"rem08 bold margin-0"}>{
                        message.sender?.name
                            ?
                            message.sender?.lastName
                                ?
                                message.sender?.name + " " + message.sender?.lastName + ":"
                                :
                                message.sender?.name + ":"

                            :
                            message.sender_name
                    }</h6>

                    {message.links?.length
                        ?
                        linkRoller(message.links)
                        :
                        null
                    }
                    <div className={"rem08 margin-0"}>{processMessageText(message?.text)}</div>
                    {message.forwarded_array?.length || message.quote_disput || message.forwarded_disput?.length || message.forwarded?.length || message.quote || message.quote_forwarded_disput?.length || message.quote_forwarded?.length
                        ?
                        <a
                            href={"#"}
                            onClick={() => showNestedMessage(message._id, from, false, 1)}
                            className={'rem07'}
                        >
                            показать вложения
                        </a>
                        :
                        null
                    }



                </div>
            )
        })

        if(on_fly) {
            return mappedArray.reverse()
        } else {
            return mappedArray
        }



    }


    function containsLink(text) {
        const linkRegex = /(https?:\/\/[^\s]+)/;
        return linkRegex.test(text);
    }


    function splitWord(text, size) {
        const chunks = [];
        let container = [];
        console.log("NOW SPLITTING")
        console.log(text)

        for (let i = 0; i < text.length; i += size) {
            chunks.push(text.slice(i, i + size));
        }


        for(let chunk of chunks) {

            container.push(<span key={chunk}>{chunk}<wbr/></span>);
        }

        return container


    }

    // function processMessageText(text) {
    //
    //     if(text) {
    //         if (containsLink(text)) {
    //             const messageContent = text.replace(
    //                 /(https?:\/\/[^\s]+)/g,
    //                 (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
    //             );
    //
    //             return <span className={"text-ws-container"} dangerouslySetInnerHTML={{ __html: messageContent }} />;
    //         }
    //         //console.log(text)
    //         const main_array = text.split(" ")
    //         let decision = false
    //
    //         let container = []
    //
    //         for(let piece of main_array) {
    //             if (piece.length > 25) {
    //                 decision = true;
    //                 break
    //             }
    //         }
    //
    //         if (decision) {
    //
    //             const chunkSize = 20;
    //
    //
    //
    //             //const wordChunks = splitWord(text, chunkSize);
    //             return splitWord(text, chunkSize);
    //             // for(let chunk of wordChunks) {
    //             //
    //             //     container.push(
    //             //         <span>{chunk}<wbr/></span>
    //             //     )
    //             // }
    //             //
    //             // return container
    //
    //         } else {
    //             return text
    //         }
    //     } else {
    //         return text
    //     }
    //
    //
    // }

    function textReducer(text) {
        if (text.length > 150) {
            return text.slice(0, 150) + "..."
        }
        return text
    }


    const newLineStopper = useRef(false)



    const renderMessages = () => {
        setMessagesToRoll([])
        console.log("RENDERING")

        console.log(messageList[activeUser?.id])
        //myRef.current.scrollTop = myRef.current.scrollHeight - scrollRef.current

        let newMessageLineSwitch = false

        isMappingInProgressRef.current = true // Устанавливаем флаг в true перед выполнением маппинга

        console.log(slicer)
        console.log(messageList[activeUser?.id])
        console.log(messageList[activeUser?.id]?.length)

        const mappedMessages =

            messageList[activeUser?.id]?.slice(0, slicer).map((message, index) => {




                // console.log(toJS(activeUser))
                console.log(index, message.text)

            let markedMessage = false

            if (markedMessages.includes(message._id)) {
                markedMessage = true
            }

            if(message.sender === store.user.id && activeUser?.id === message.receiver) {
                //флаг о необходимости разделительной линии дат
                const horizontal = new Date(lastDate).toDateString() !== new Date(message.date).toDateString()
                const date = new Date(lastDate)
                //обновление переменной с последней датой, именно в такой строжайшей последовательности
                lastDate = message.date
                newMessagesLine = false

                if(newMessageLineSwitch) {

                    newMessagesLine = true
                    newMessageLineSwitch = false
                    newLineStopper.current = true
                    // if (!hasNewMessages) {
                    //     console.log("SET 42")
                    //     setHasNewMessages(true)
                    // }
                }


                return <div
                    id={message._id}
                    key={message._id}
                    className={`private-main-wrapper private-main-wrapper-right ${highLightedMessage === message._id ? "highlighted-message" : null} ${markedMessage ? "marked-message" : null}`}
                    onClick={(e) => handleClick(e)}
                >
                    <div>
                        {horizontal && index !== 0 && !newMessagesLine ? <h6 className="private-chat-hr">{new Date(date).toLocaleDateString()}</h6> : null}
                        {newMessagesLine && index !== 0 ?  <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}
                    </div>
                    <div className={"private-chat-message-wrapper-right"}>
                        <img
                            className={"private-arrow-svg arrows dont-drag"}
                            src={SvgAnswer}
                            alt="Ответить"
                            width={"25px"}

                            onClick={(e) => {
                                answerOnMessage(message.text, message._id, message.date, true, message.links, message.forwarded, message.forwarded_disput, message.quote, message.quote_disput)
                            }}
                        />
                        <img
                            className={"private-arrow-svg arrows dont-drag"}
                            src={SvgForward}
                            alt="Переслать"
                            width={"25px"}
                            onClick={() => {
                                console.log(message._id)
                                setMarkedMessages(prev => {
                                    if (!prev.includes(message._id)) {
                                        // Если элемент отсутствует, возвращаем массив с этим элементом
                                        return [message._id];
                                    }
                                    // Если элемент уже присутствует, возвращаем пустой массив
                                    return [];
                                });
                                document.querySelector("#user-list").classList.toggle("rotated-180")
                            }}
                        />
                        {getMinutesBetweenDates(new Date (message.date).getTime(), Date.now()) < 60
                            ?
                            <img
                                className={"private-arrow-svg arrows dont-drag"}
                                src={SvgPencil}
                                alt="Редактировать"
                                width={"25px"}

                                onClick={(e) => {
                                    editMessage(message._id, message.text, message.links)
                                }}
                            />
                            :
                            null
                        }
                        <div className={"private-chat-message-right"} key={message.date}>

                            <div className={"private-date-and-read-container"}>
                                <p className={"private-message-date"}>
                                    {dateSet(message.date)}
                                </p>
                                <img
                                    className={"dont-drag work-read-img margin-left-20"}
                                    src={message.read ? SvgCheckRead : SvgCheckUnread}

                                    width={"17px"}/>
                            </div>



                            {/*{message.forwarded?.length*/}
                            {/*    ?*/}
                            {/*    <div className={"private-message-forwarded-container"}>*/}
                            {/*        <h6 className={"rem08"}>Пересылаемые сообщения:</h6>*/}
                            {/*        {renderForwardedMessages(message.forwarded, message._id)}*/}
                            {/*    </div>*/}

                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}

                            {message.forwarded_array?.length
                                ?
                                <div className={"private-message-forwarded-container"}>
                                    <h6 className={"rem08"}>Пересылаемые сообщения{chatNameResolver(message.forwarded_from, store.chatList)}:</h6>
                                    {renderForwardedMessages(message.forwarded_array, message._id, message.forwarded_from, message.on_fly)}
                                </div>

                                :
                                null
                            }


                            {message.quote
                                ?
                                <div className={"answered-message answered-right"}>

                                    {/*<p className={"private-message-date rem07"}>*/}
                                    {/*    {dateSet(message.quote.date ? message.quote.date : message.quote_date)}*/}
                                    {/*</p>*/}
                                    {/*<span className={"bold rem08"}>{message.quote_self ? "Вы" : activeUser.username}:</span>*/}
                                    <div className={"nick-and-go"}>
                                        <span className={"bold rem08"}>{message.quote_self ? "Я" : activeUser.username}:</span>
                                        <img
                                            title={"Перейти к сообщению"}
                                            src={SvgGoToMsg} width={"15px"}
                                            className={"pointer nowrap margin-left-10"}
                                            onClick={(e) => goToQuotedMessage(e, message.quote)}
                                        />
                                        {/*<div*/}
                                        {/*    title={"Перейти к сообщению"}*/}
                                        {/*    className={"pointer nowrap margin-left-10"}*/}
                                        {/*    onClick={(e) => goToQuotedMessage(e, message.quote)}*/}
                                        {/*>⏫</div>*/}
                                    </div>
                                    {message.quote_links?.length || message.quote.links
                                        ?
                                        linkRoller(message.quote_links || message.quote.links)
                                        :
                                        null
                                    }

                                    <span className={"rem08"}>
                                        {message.quote.text
                                            ?
                                            processMessageText(message.quote.text)
                                            :
                                            processMessageText(message.quote_text)
                                        }
                                    </span>

                                    {message.forwarded_array?.length || message.quote.quote_disput || message.quote.forwarded_disput?.length || message.quote.forwarded?.length || message.quote.quote
                                        ?
                                        <a
                                            href={"#"}
                                            onClick={() => showNestedMessage(message.quote._id ? message.quote._id : message.quote , undefined, false, 2)}
                                            className={'rem07'}
                                        >показать вложения</a>
                                        :
                                        null
                                    }
                                    {message.need_rolling
                                        ?
                                        <a
                                            href={"#"}
                                            onClick={() => showNestedMessage(message.quote._id ? message.quote._id : message.quote , undefined, false, 2)}
                                            className={'rem07'}
                                        >показать вложения</a>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }
                            {message.links.length
                                ?
                                linkRoller(message.links)
                                :
                                null
                            }
                            {processMessageText(message.text)}
                            {message.edited
                                ?
                                <div className={"edit-mark-wrapper"}>
                                    <p className={"hints-on-light-blue"}>(ред.)</p>
                                </div>

                                :
                                null
                            }
                        </div>
                    </div>
                    {messageList[activeUser?.id]?.slice(0, slicer).length - 1 === index && !extremeValuesRef.current[activeUserRef.current]?.firstId ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                </div>

            } else if (message.receiver === store.user.id && activeUser?.id === message.sender) {

                // console.log(message)
                //флаг о необходимости разделительной линии дат
                const horizontal = new Date(lastDate).toDateString() !== new Date(message.date).toDateString()
                const date = new Date(lastDate)
                //обновление переменной с последней датой, именно в такой строжайшей последовательности
                lastDate = message.date

                newMessagesLine = false


                if((message.read || markedElements.current.has(message._id)) && newMessageLineSwitch) {
                    //console.log(message.text)
                    newMessagesLine = true
                    newMessageLineSwitch = false

                    // if (!hasNewMessages) {
                    //     console.log("SET 4")
                    //     setHasNewMessages(true)
                    // }


                }

                if(index === 0 && !message.read && !newLineStopper.current) {
                    if(!markedElements.current.has(message._id)) {
                        console.log("ВКЛЮЧАЕМ ЛИНИЮ")
                        newMessageLineSwitch = true

                    }

                }





                return <div
                    id={message._id}
                    key={message._id}
                    className={`private-main-wrapper private-main-wrapper-left ${highLightedMessage === message._id ? "highlighted-message" : null} ${markedMessage ? "marked-message" : null}`}
                    onClick={(e) => handleClick(e)}
                >
                    <div>
                        {horizontal && index !== 0 && !newMessagesLine ? <h6 className="private-chat-hr">{new Date(date).toLocaleDateString()}</h6> : null}
                        {newMessagesLine && index !== 0 ?  <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}
                    </div>

                    <div className={"private-chat-message-wrapper-left"}>
                        <div
                            className={"private-chat-message-left"}
                            key={message.date}
                            data-read={message.read}
                            // id={message._id + '000'}
                            id={message._id}
                        >

                            <p className={"private-message-date"}>
                                {dateSet(message.date)}
                            </p>

                            {/*{message.forwarded?.length*/}
                            {/*    ?*/}
                            {/*    <div className={"private-message-forwarded-container"}>*/}
                            {/*        <h6 className={"rem08"}>Пересылаемые сообщения:</h6>*/}
                            {/*        {renderForwardedMessages(message.forwarded, message._id)}*/}
                            {/*    </div>*/}

                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}

                            {message.forwarded_array?.length
                                ?
                                <div className={"private-message-forwarded-container"}>
                                    <h6 className={"rem08"}>Пересылаемые сообщения{chatNameResolver(message.forwarded_from, store.chatList)}:</h6>
                                    {renderForwardedMessages(message.forwarded_array, message._id, message.forwarded_from, message.on_fly)}
                                </div>

                                :
                                null
                            }

                            {message.quote
                                ?
                                <div className={"answered-message answered-left"}>

                                    {/*<p className={"private-message-date"}>*/}
                                    {/*    {dateSet(message.quote.date ? message.quote.date : message.quote_date)}*/}
                                    {/*</p>*/}
                                    <div className={"nick-and-go"}>
                                        <span className={"bold rem08"}>
                                            {message.quote_self ? activeUser.username : "Я"}
                                            :</span>
                                        <img
                                            title={"Перейти к сообщению"}
                                            src={SvgGoToMsg} width={"15px"}
                                            className={"pointer nowrap margin-left-10"}
                                            onClick={(e) => goToQuotedMessage(e, message.quote)}

                                        />
{/*<div*/}
                                        {/*    title={"Перейти к сообщению"}*/}
                                        {/*    className={"pointer nowrap margin-left-10"}*/}
                                        {/*    onClick={(e) => goToQuotedMessage(e, message.quote)}*/}
                                        {/*>⏫</div>*/}
                                    </div>
                                    {message.quote_links?.length || message.quote.links
                                        ?
                                        linkRoller(message.quote_links || message.quote.links)
                                        :
                                        null
                                    }

                                    <div className={"rem08 margin-0"}>
                                        {message.quote.text
                                            ?
                                            processMessageText(message.quote.text)
                                            :
                                            processMessageText(message.quote_text)
                                        }
                                    </div>
                                    {message.forwarded_array?.length || message.quote.quote_disput || message.quote.forwarded_disput?.length || message.quote.forwarded?.length || message.quote.quote
                                        ?
                                        <a
                                            href={"#"}
                                            onClick={() => showNestedMessage(message.quote._id, message.quote.forwarded_from, false, 3)}
                                            className={'rem07'}
                                        >
                                            показать вложения
                                        </a>
                                        :
                                        null
                                    }
                                    {message.need_rolling
                                        ?
                                        <a
                                            href={"#"}
                                            onClick={() => showNestedMessage(message.quote._id ? message.quote._id : message.quote , message.quote.forwarded_from, false, 2)}
                                            className={'rem07'}
                                        >показать вложения</a>
                                        :
                                        null
                                    }


                                </div>
                                :
                                null
                            }

                            {message.links.length
                                ?
                                linkRoller(message.links)
                                :
                                null
                            }
                            {processMessageText(message.text)}
                            {message.edited
                                ?
                                <div className={"edit-mark-wrapper"}>
                                    <p className={"hints-on-orange"}>(ред.)</p>
                                </div>

                                :
                                null
                            }

                        </div>
                        <img
                            className={"private-arrow-svg arrows dont-drag"}
                            src={SvgAnswer}
                            alt="Ответить"
                            width={"25px"}
                            onClick={(e) => {
                                answerOnMessage(message.text, message._id, message.date, false, message.links, message.forwarded, message.forwarded_disput, message.quote, message.quote_disput)
                            }}
                        />
                        <img
                            className={"private-arrow-svg arrows dont-drag"}
                            src={SvgForward}
                            alt="Переслать"
                            width={"25px"}
                            onClick={() => {
                                console.log(message._id)
                                setMarkedMessages(prev => {
                                    if (!prev.includes(message._id)) {
                                        // Если элемент отсутствует, возвращаем массив с этим элементом
                                        return [message._id];
                                    }
                                    // Если элемент уже присутствует, возвращаем пустой массив
                                    return [];
                                });
                                document.querySelector("#user-list").classList.toggle("rotated-180")
                            }}
                        />

                    </div>



                </div>
            } else {
                console.log("BUDUMTSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSs")
            }
        })

        isMappingInProgressRef.current = false // Устанавливаем флаг в false после завершения маппинга

        //return mappedMessages;

        setMessagesToRoll(mappedMessages)

        if(slicer === 100) {
            newLineStopper.current = true
        }




    };

    const [markedMessages, setMarkedMessages] = useState([])



    useEffect(() => {
        console.log("ЭФФЕКТИК")
        makeSortedIds(messageList)

    }, [messageList, refreshSortedIds])

    useEffect(() => {



        // console.log("BLINK - АКТУАЛЬНОЕ СОСТОЯНИЕ messageList")
        // console.log(messageList[activeUserRef.current])
        // console.log("BLINK - АКТУАЛЬНОЕ СОСТОЯНИЕ extremeValues")
        // console.log(extremeValues[activeUserRef.current])


        if(messageList && activeUser) {
            console.log("EFFECT 000")
            console.log(messageList.length)
            console.log(toJS(activeUser))
            console.log(markedMessages.length)
            console.log("RENDER BY messageList or activeUser")
            renderMessages()

        }

    }, [messageList, activeUser, markedMessages, slicer])


    useEffect(() => {
        console.log(messageList)
    }, [messageList])



    useEffect(() => {
        if(!markedMessages.length) {
            document.querySelector("#user-list").classList.remove("rotated-180")
            setForwardingList([])
            setForwardingChatList([])
            setDisableForward(false)
        }
    }, [markedMessages])


    const intervalRef = useRef(null)
    const [rolledUsers, setRolledUsers] = useState([])





    const [alreadySliced, setAlreadySliced] = useState([])


    useEffect(() => {
        console.log("EFFECT 9")


        const storedSoundOn = localStorage.getItem(`sound_private_${activeUser?.id}`);
        const currentSoundOn = storedSoundOn ? JSON.parse(storedSoundOn) : true;

        setSoundOn(currentSoundOn)




        activeUserRef.current = activeUser?.id
        //clearInterval(intervalRef.current)
        setMarkedMessages([])
        newLineStopper.current = false

        if(messageList[activeUser?.id]?.length > slicer && !alreadySliced?.includes(activeUser?.id)) {
            setSlicer(messageList[activeUser?.id]?.length + 10)
            setAlreadySliced(prev => [...prev, activeUser?.id])
        } else {
            setSlicer(100)
        }

        if (tempStorage.hasOwnProperty(activeUser?.id)) {

            console.log("SML 12");
            console.log(messageList)

            setMessageList(prevState => {
                const newMessages = { ...prevState };
                console.log(newMessages[activeUser?.id]);

                if (newMessages[activeUser?.id]) {
                    console.log("у нас ИФ");
                    newMessages[activeUser?.id] = [...tempStorage[activeUser?.id], ...newMessages[activeUser?.id]];
                } else {
                    console.log("у нас ЭЛЗ");
                    newMessages[activeUser?.id] = [...tempStorage[activeUser?.id]];
                }

                delete tempStorage[activeUser?.id];
                console.log(newMessages);
                return newMessages;
            });
        }



        // if(temporaryMessageStore.current.hasOwnProperty(activeUser?.id)) {
        //     console.log("SML 12")
        //     console.log(temporaryMessageStore.current[activeUser?.id])
        //     setMessageList(prevState => {
        //         const newMessages = { ...prevState };
        //         console.log(newMessages[activeUser.id])
        //         if (newMessages[activeUser.id]) {
        //             console.log("у нас ИФ")
        //             newMessages[activeUser.id] = [...temporaryMessageStore.current[activeUser.id], ...newMessages[activeUser.id]]
        //         } else {
        //             console.log("у нас ЭЛЗ")
        //             newMessages[activeUser.id] = [...temporaryMessageStore.current[activeUser.id]];
        //         }
        //
        //         delete temporaryMessageStore.current[activeUser.id]
        //         console.log(newMessages[activeUser.id])
        //         return newMessages;
        //     });
        //
        // }




        // if(activeUser && rolledUsers.indexOf(activeUserRef.current) === -1) {
        //     let counter = 0
        //     intervalRef.current = setInterval(() => {
        //
        //         counter++
        //
        //         if(myRef.current.querySelector('.new-message-line')) {
        //             console.log('5 scroll')
        //             myRef.current.scrollTo({
        //                 top: myRef.current.querySelector('.new-message-line').offsetTop - 150, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
        //                 //behavior: 'smooth', // Прокручиваем с анимацией
        //             })
        //             console.log("SET 6")
        //             setRolledUsers(prev => [...prev, activeUser.id])
        //             clearInterval(intervalRef.current)
        //         }
        //         if(counter > 9) {
        //             console.log("SET 7")
        //             setRolledUsers(prev => [...prev, activeUser.id])
        //             clearInterval(intervalRef.current)
        //         }
        //     }, 500)
        //
        // }




        // return () => {
        //     clearInterval(intervalRef.current);
        // }

    }, [activeUser]);

    useEffect(() => {
        console.log(messageList)
    }, [messageList])



    async function removeUserFromList(user) {

        console.log("User is - ", user)

        const response = await $api.post('/remove_user_from_private_list', {my_id: store.user.id, remove: user.id})

        if(response.status < 400) {
            console.log("Removing from store private user list below")
            store.removeFromPrivateList(user.id)
            if(activeUser?.id === user) {
                console.log("УДАЛЯЕМ АКТИВОГО")
                activeUserRef.current = null
                setActiveUser()
            }

            const newUserList = userList.filter(item => item.id !== user.id)
            console.log("SETTING USERLIST 5")
            setUserList(newUserList)

            const newStateOfIdsList = stateOfIdsOfFullUserList.filter(item => item !== user.id)
            setStateOfIdsOfFullUserList(newStateOfIdsList)

        }



    }



    function handleClick(event) {

        if(window.getSelection().toString()) {
            return;
        }

        const current_message_id = event.currentTarget.id



        //если событие клика происходит не на элементе с селектором arrows
        if (!event.target.matches('.arrows')
            && !event.target.matches('.chat-pre-image')
            && !event.target.matches('a')
            && !event.target.closest('.answered-message')
        ) {

            // Если клик произошел на блоке div, выполняем действие
            if (markedMessages.indexOf(current_message_id) === -1) {
                console.log("SET 8")
                setMarkedMessages(prev => [...prev, current_message_id])
            } else {
                const newMarkedList = markedMessages.filter(item => item !== current_message_id)
                console.log("SET 9")
                setMarkedMessages(newMarkedList)
            }

        }
    };


    useEffect(() => {
        if(printedMessage === "") {
            textAreaRef.current.style.height = "auto"
        }
        console.log("ADJUSTING 1")
        textAreaAdjust()
    }, [printedMessage])

    const lastTextHeight = useRef(0)

    function textAreaAdjust() {

        const maxHeight = 300

        textAreaRef.current.style.height = "auto"

        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        if(textAreaRef.current.scrollHeight > lastTextHeight.current) {

            lastTextHeight.current = textAreaRef.current.scrollHeight

        }

        if (textAreaRef.current.scrollHeight > maxHeight) {
            textAreaRef.current.style.height = maxHeight + "px";
            textAreaRef.current.style.overflowY = "scroll";
        } else {
            textAreaRef.current.style.overflowY = "hidden";
        }


        if(textAreaRef.current.scrollHeight > 28) {
            window.scrollTo(0, document.body.scrollHeight)
        }
    }


    async function downArrowAction() {
        console.log("DOWN ARROW CLICK")


        if(extremeValuesRef.current[activeUserRef.current].firstId) {

            await getAnother100Messages(null, null)

            setTimeout(async () => {
                console.log("SCROLL TO 5")
                await myRef.current.scrollTo({
                    top: 0
                })
                setShowArrowDown(false)

            }, 500)

        } else {
            console.log("scroll to 995")
            myRef.current.scrollTo({top: 0})
        }



        if(extremeValuesRef.current[activeUserRef.current].firstId || unreadMessages[activeUserRef.current]?.length) {
            console.log("MARKA 5")
            await markAsRead("all", activeUser.id)
            console.log('6 scroll')
            myRef.current.scrollTo({top: 0, })

        } else {
            console.log('7 scroll')
            myRef.current.scrollTo({top: 0,})
        }


    }


    useEffect(() => {

        if(privateEditingData) {
            refreshMessagesArray()
        }

    }, [privateEditingData])


    const queueTimer = useRef()


    async function updateMessagesReadStatus(object) {

        stopMakingObserver.current = true
        console.log("SML 13")
        setMessageList((prevMessages) => {
            //const messageId = privateEditingData.message_id;

            // Создаем копию объекта messages
            const updatedMessages = { ...prevMessages };

            // Проверяем каждый ключ в объекте messages
            //for (const userId in updatedMessages) {
            if (updatedMessages.hasOwnProperty(object.reader) && Array.isArray(updatedMessages[object.reader])) {

                const userMessages = updatedMessages[object.reader].map((message) => {
                    if (object.messagesIdArray.includes(message._id)) {
                        return {
                            ...message,
                            read: true,
                        };
                    }
                    return message;
                });

                updatedMessages[object.reader] = userMessages

                return updatedMessages

                //
                //
                // const userMessages = updatedMessages[privateEditingData.reader];
                //
                // // Находим сообщение для редактирования в массиве пользователя
                // const index = userMessages.findIndex((message) => message._id === messageId);
                //
                // if (index !== -1) {
                //     // Если _id совпадает, обновляем сообщение
                //     userMessages[index] = {
                //         ...userMessages[index],
                //         read: true,
                //     };
                //
                //     // Возвращаем обновленный объект messages
                //     return updatedMessages;
                // }
            }
            //}

            // Если ни один пользовательский массив не содержит сообщения с указанным _id, возвращаем исходный объект messages
            return updatedMessages;
        });
    }




    async function mergeArraysAndProcess(inputArray) {
        // Группируем объекты по полю order_id
        const groupedObjects = inputArray.reduce((groups, obj) => {
            const userId = obj.reader;
            if (!groups[userId]) {
                groups[userId] = [];
            }
            groups[userId].push(obj);
            return groups;
        }, {});

        // Обработка каждой группы
        for (const userId in groupedObjects) {
            if (groupedObjects.hasOwnProperty(userId)) {
                const group = groupedObjects[userId];

                // Создаем объект для хранения уникальных элементов messagesIdArray
                let uniqueMessages = {};

                // Проходим по каждому объекту в группе
                group.forEach(obj => {
                    // Объединяем значения messagesIdArray в объект uniqueMessages
                    obj.messagesIdArray.forEach(messageId => {
                        uniqueMessages[messageId] = true;
                    });
                });

                // Создаем объект для результирующего массива
                let resultObject = {
                    // Выбираем любой объект из группы для копирования значений
                    ...group[0],
                    // Присваиваем объединенный массив uniqueMessages полю messagesIdArray
                    messagesIdArray: Object.keys(uniqueMessages)
                };

                // Передаем объект в функцию и ждем выполнения
                await updateMessagesReadStatus(resultObject);
            }
        }
    }


    useEffect(() => {

        if(privateServiceMessage.length) {



            clearTimeout(queueTimer.current)

            queueTimer.current = setTimeout(() => {

                //updateMessagesReadStatus(mergeArrays(orderServiceMessage))
                console.log("Тут запускается магия!")
                setPrivateServiceMessage([])
                mergeArraysAndProcess(privateServiceMessage)

            }, 1500)


        }

    }, [privateServiceMessage])





    const [editedText, setEditedText] = useState("")
    const [editedMessageId, setEditedMessageId] = useState()
    const [isMessageEditing, setIsMessageEditing] = useState(false)
    const [deleteThisFiles, setDeleteThisFiles] = useState([])
    const [showDelButton, setShowDelButton] = useState(true)
    //
    function editMessage(messageId, text, links) {
        cancelAnswering()
        document.getElementById("chat-input").focus()
        console.log(messageId, text, links)
        setIsMessageEditing(true)
        setEditedMessageId(messageId)
        setEditedText(text)
        setPinnedFiles(links)
    }
    //
    //
    useEffect(() => {
        console.log("ADJUSTING 2")
        textAreaAdjust()
    }, [editedText])
    //
    function cancelEditing() {
        setIsMessageEditing(false)
        setEditedMessageId()
        setEditedText("")
        setPinnedFiles([])
        setDeleteThisFiles([])
        setShowDelButton(true)
    }


    async function deleteMessage() {

        const response = await $api.delete(`/delete_private_message?message_id=${editedMessageId}`,)
        if(response.data.message === "OK") {
            cancelEditing()
            console.log("ADJUSTING 3")
            textAreaAdjust()
        }

    }

    //
    async function saveEditedMessage() {

        const response = await $api.post('/private_message_edit', {message_id: editedMessageId, text: editedText, pinnedFiles, deleteThisFiles})

        //if(response.data.message === "OK") {
            cancelEditing()
        console.log("ADJUSTING 4")
            textAreaAdjust()
        //}
    }
    //
    //
    function refreshMessagesArray() {

        stopMakingObserver.current = true

        // console.log(unreadUsers)

        const messageId = privateEditingData.message_id;

        if(privateEditingData.action === "edit") {

            console.log(privateEditingData)
            console.log(lastMessagesText)

            // Создаем копию временного хранилища
            const updatedTempStorage = { ...tempStorage };

            // Проходимся по всем ключам временного хранилища
            for (const key in updatedTempStorage) {
                if (updatedTempStorage.hasOwnProperty(key) && Array.isArray(updatedTempStorage[key])) {
                    console.log("МЫ В НУЖНОЙ ВВЕТКЕ")
                    const currentArray = updatedTempStorage[key];
                    const index = currentArray.findIndex(obj => obj._id === messageId);

                    if (index !== -1) {
                        // Обновляем данные сообщения в временном хранилище
                        updatedTempStorage[key][index].text = privateEditingData.text;
                        updatedTempStorage[key][index].links = privateEditingData.pinnedFiles;
                        updatedTempStorage[key][index].edited = true;

                        // Если редактируется первое сообщение в чате, обновляем lastMessagesText
                        if (index === 0) {
                            setLastMessagesText(prev => ({
                                ...prev,
                                [key]: privateEditingData
                            }));
                        }

                        // Выходим из цикла, так как нашли и обновили нужное сообщение
                        break;
                    }
                }
            }


            //тут обновляем состояние последнего сообщения для отображения его в списке диалогов
            const newStateOfLastmessages = { ...lastMessagesText }
            let needToRefreshLastMessagesText = false

            for(let key in newStateOfLastmessages) {
                console.log(newStateOfLastmessages[key]?._id)
                if(newStateOfLastmessages[key]?._id === privateEditingData.message_id) {
                    newStateOfLastmessages[key].text = privateEditingData.text
                    needToRefreshLastMessagesText = true
                    break;
                }
            }
            if(needToRefreshLastMessagesText) {
                setLastMessagesText(newStateOfLastmessages)
            }
            //______________________________________________________________________

            // Обновляем временное хранилище целиком
            setTempStorage(updatedTempStorage);


            console.log("SML 14")
            setMessageList((prevMessages) => {
                const messageId = privateEditingData.message_id;

                // Создаем копию объекта messages
                const updatedMessages = { ...prevMessages };

                // Проверяем каждый ключ в объекте messages
                for (const userId in updatedMessages) {
                    if (updatedMessages.hasOwnProperty(userId) && Array.isArray(updatedMessages[userId])) {
                        const userMessages = updatedMessages[userId];

                        // Находим сообщение для редактирования в массиве пользователя
                        const index = userMessages.findIndex((message) => message._id === messageId);

                        if (index !== -1) {
                            // Если _id совпадает, обновляем сообщение
                            userMessages[index] = {
                                ...userMessages[index],
                                text: privateEditingData.text,
                                links: privateEditingData.pinnedFiles,
                                edited: true,
                            };

                            // Возвращаем обновленный объект messages
                            return updatedMessages;
                        }

                        if(index === 0) {
                            setLastMessagesText(prev => {
                                return {
                                    ...prev,
                                    [userId]: privateEditingData
                                }
                            })
                        }

                    }
                }

                // Если ни один пользовательский массив не содержит сообщения с указанным _id, возвращаем исходный объект messages
                return updatedMessages;
            });


        } else if (privateEditingData.action === "delete") {

            console.log("НАДО УДАЛИТЬ")


            let stopRefreshLastMessage = false

            if(privateEditingData.sender !== store.user.id) {

                setUnreadMessages(prev => {
                    const newState = { ...prev };
                    // const user_id = message.user_id;
                    // const message_id = message.message_id;

                    if (newState.hasOwnProperty(privateEditingData.sender)) {
                        newState[privateEditingData.sender] = newState[privateEditingData.sender].filter(id => !privateEditingData.message_ids.includes(id));
                    }



                    return newState;
                });



                setTempStorage(prev => {
                    const newState = { ...prev };
                    const user_id = privateEditingData.sender;
                    const message_id = privateEditingData.message_ids[0];

                    if (newState.hasOwnProperty(user_id)) {
                        newState[user_id] = newState[user_id].filter(message => message._id !== message_id);
                        console.log(newState[user_id].length !== tempStorage[user_id].length)
                        console.log(lastMessagesText[user_id]?._id === message_id)

                        if(newState[user_id].length !== tempStorage[user_id].length) {
                            if(newState[user_id]?.length) {
                                console.log("У нас ИФ")
                                setLastMessagesText(prev => {
                                    return {
                                        ...prev,
                                        [user_id]: newState[user_id][0]
                                    }
                                })
                            } else {
                                console.log("У нас ЭЛЗ")
                                console.log(messageList)
                                console.log(user_id)
                                setLastMessagesText(prev => {
                                    return {
                                        ...prev,
                                        [user_id]: messageList[user_id][0]
                                    }
                                })
                            }
                            stopRefreshLastMessage = true
                        }
                    }

                    // console.log(!stopRefreshLastMessage && lastMessagesText[user_id]?._id === message_id)
                    //
                    // if(!stopRefreshLastMessage && lastMessagesText[user_id]?._id === message_id) {
                    //     console.log("ДА, ЭТО ТАК")
                    //     console.log(newState[user_id]?.length)
                    //
                    // }

                    return newState;
                })

            }




            console.log("SML 15")
            setMessageList((prevMessages) => {
                const message_id = privateEditingData.message_ids[0];
                const sender_id = privateEditingData.sender
                const receiver_id = privateEditingData.receiver

                console.log("NEED TO DELETE NEXT")

                // Создаем копию объекта messages
                let updatedMessages = { ...prevMessages };

                if (updatedMessages.hasOwnProperty(sender_id === store.user.id ? receiver_id : sender_id)) {
                    updatedMessages[sender_id === store.user.id ? receiver_id : sender_id] = updatedMessages[sender_id === store.user.id ? receiver_id : sender_id].filter(message => message._id !== message_id);

                }

                if(!stopRefreshLastMessage) {
                    console.log("ДА, ЭТО ТАК")

                    setLastMessagesText(prev => {
                        return {
                            ...prev,
                            [sender_id === store.user.id ? receiver_id : sender_id]: updatedMessages[sender_id === store.user.id ? receiver_id : sender_id][0]
                        }
                    })
                }

                return updatedMessages
            });
        }
    }


    const [shiftEnterPressed, setShiftEnterPressed] = useState(false);



    const [drag, setDrag] = useState(false)

    const handleDragStart = (event) => {
        event.preventDefault()

        if (!event.target.classList.contains('dont-drag')) {
            event.preventDefault();
            setDrag(true);
            console.log("DragStart");
        }

    };

    const handleDragLeave = (event) => {
        event.preventDefault()
        setDrag(false)
        console.log("DragLeave")
    };

    const onDropHandler = (e) => {
        e.preventDefault()

        setDrag(false)
        handleFiles(e.dataTransfer.files)

    }

    async function handlePaste(e) {
        //e.preventDefault();

        const items = e.clipboardData.items;

        if (items) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].kind === 'file') {
                    console.log('Есть файлы');
                    setSpinner(true);
                    break;  // Прерываем цикл, так как файл уже найден
                }
            }
        }


        const formData = new FormData();

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if(item.size > 5000000){
                setErrorMessage({show: true, text: "Размер файла не должен превышеть 5мб"})
                return false
            }

            if (item.type.indexOf("image") !== -1) {
                const blob = item.getAsFile();
                formData.append("files[]", blob, `pasted-image-${v4()}.png`);
            }
        }

        if (formData.has("files[]")) {
            try {
                if(pinnedFiles.length < 3) {
                    const response = await $api.post(`/load_private_files/${store.user.id}`, formData);
                    console.log(response.data)
                    if(response.status < 400) {
                        setSpinner(false)
                        await setPinnedFiles(prev => [...prev, ...response.data.files])
                    }
                } else {
                    setSpinner(false)
                    setErrorMessage({show: true, text: "К одному сообщению можно приложить не более 3-х файлов"})
                }

            } catch (error) {
                // Обработай ошибку, если необходимо
            }
        }
        console.log("ADJUSTING 5")
        textAreaAdjust()

    }



    const [threeDots, setThreeDots] = useState("...")
    const [somebodyTyping, setSomebodyTyping] = useState(true)
    const [typersArray, setTypersArray] = useState([])


    const timersRef = useRef({});


    useEffect(() => {

        if(typersArray.length) {
            setSomebodyTyping(true)
        } else {
            setSomebodyTyping(false)
        }


        if(typingUserPrivate?.id) {


            if (timersRef.current[typingUserPrivate?.id]) {
                clearTimeout(timersRef.current[typingUserPrivate?.id]);
                timersRef.current[typingUserPrivate?.id] = setTimeout(() => {
                    setTypersArray(prev => prev.filter(user => user.id !== typingUserPrivate?.id));
                }, 4000);
            } else {
                timersRef.current[typingUserPrivate?.id] = setTimeout(() => {
                    setTypersArray(prev => prev.filter(user => user.id !== typingUserPrivate?.id));
                }, 4000);
            }



            setTypersArray(prev => {
                if (prev.some(user => user.id === typingUserPrivate?.id)) {
                    // Если уже есть объект с таким `id`, не добавляем его повторно
                    return prev;
                } else {
                    // Если нет объекта с таким `id`, добавляем `typingUser`
                    return [typingUserPrivate, ...prev];
                }
            })


        }
    }, [typingUserPrivate])

    useEffect(() => {


        if(somebodyTyping) {
            setTimeout(() => {

                if(threeDots.length === 0) {
                    setThreeDots(prev => prev + ".")
                }
                if(threeDots.length === 1) {
                    setThreeDots("..")
                }
                if(threeDots.length === 2) {
                    setThreeDots("...")
                }
                if(threeDots.length === 3) {
                    setThreeDots("")
                }

            }, 300)
        }

    }, [threeDots, somebodyTyping])



    //здесь с помощью chatGPT я придумал добавит хук useCallback, во время решения проблемы
    //предотвращения бесконечной отправки служебного сообщения о том что юзер набирает текст
    //после каждого нажатия а кнопку клавиатуры, на момент написания комментария я не совсем понимаю почему
    //это помогло, изначально я просто хотел использовать функцию throttle для предотвращения
    //большого количества срабатываний, но она не работала по той логике, которою я от нее хотел
    //а так всё работает, ну и нехай себе работает, не будем трогать))
    const userTyping = useCallback(() => {
        console.log("USER TYPING");
        console.log(activeUserRef.current)
        notifySocket.current.send(JSON.stringify({
            event: 'user_typing_private',
            to: activeUserRef.current,
            user: { id: store.user.id, name: store.user.name }
        }));
    }, []);


    const [debounceTimeout, setDebounceTimeout] = useState(false)

    const iAmTyping = useCallback(throttle(userTyping, 1500), []);


    function containActiveId() {
        const idArray = typersArray.map(user => user.id)
        if(idArray.includes(activeUserRef.current)) {
            return true
        } else {
            return false
        }
    }


    return (
        <div className={"private-chat-wrapper"}>
            <div className={"private-chat-user-list-wrapper"} id={"private-chat-user-list-wrapper"}>

                <div className={"private-chat-user-list"} id={"user-list"}>
                    <div className={"front-user-list-card"}>

                        <div className={"lens-and-search-input-container"}>
                            <div className={"lens-stroka"}>
                                <img
                                    className={"dont-drag search-lens"}
                                    src={SvgSearchLens}
                                    alt="Ответить"
                                    width={"25px"}
                                />
                                <input
                                    className={"private-user-search"}
                                    value={searchUserValue}
                                    onChange={(e) => setSearchUserValue(e.target.value)}
                                />
                            </div>
                            {showSearch
                                ?
                                <div className={"private-search-list"}>
                                    {/*<h4>Slovo!</h4>*/}
                                    {/*<h4>Slovo2!</h4>*/}
                                    {userListForSearch.map(user => {


                                        if(((user.name?.toLowerCase() + " " + user.lastName?.toLowerCase()).includes(searchUserValue.toLowerCase())
                                                || (user.lastName?.toLowerCase() + " " + user.name?.toLowerCase()).includes(searchUserValue.toLowerCase()))
                                            && user.id !== store.user.id
                                        )

                                            return (
                                                <div
                                                    key={user.id}
                                                    className={"margin-bottom-10 founded-private-user"}
                                                    onClick={() => {

                                                        setShowSearch(false)

                                                        for(let user_item of userList) {

                                                            if (user_item.id === user.id) {
                                                                setActiveUser(user_item)
                                                                setSearchUserValue("")
                                                                return
                                                            }
                                                        }

                                                        console.log("SETTING USER LIST 5")
                                                        setUserList(prev => [{id: user.id, username: user.name + " " + user.lastName}, ...prev])
                                                        setActiveUser({id: user.id, username: user.name + " " + user.lastName})
                                                        addNewUserAndHisMessagesToLists(user.id, store.user.id)
                                                        store.addToPrivateUserList({id: user.id, username: user.name + " " + user.lastName})
                                                        store.addToFullUserList({id: user.id, username: user.name + " " + user.lastName})
                                                        setSearchUserValue("")
                                                    }}
                                                >
                                                    <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                                    {user.name + " " + user.lastName}
                                                </div>
                                            )
                                    })}
                                </div>
                                :
                                null
                            }
                        </div>



                        {!showSearch
                            ?
                            userListToRoll
                            :
                            nowUserSearching
                                ?
                                <div className={"private-user-search-spinner-container"}>
                                    <img src={require('../img/loading.gif')} width={"35px"} className={"dont-drag"}/>
                                </div>
                                :
                                null
                        }
                    </div>

                    <div className={"back-user-list-card"}>
                        {/*<h6 className={"ubuntu"}>Кому:</h6>*/}
                        <div className={"forward-choice-button-container margin-bottom-20"}>
                            <button
                                className={`btn rem07 ${forwardToChat ? "secondary-btn-color" : "primary-btn-color" }`}
                                onClick={() => {
                                    setForwardToChat(false)
                                    setForwardingChatList([])
                                }}
                            >В личку</button>
                            <button
                                className={`btn rem07 ${!forwardToChat ? "secondary-btn-color" : "primary-btn-color" } margin-left-20`}
                                onClick={() => {
                                    setForwardToChat(true)
                                    setForwardingList([])
                                }}
                            >В другой чат</button>
                        </div>



                        {forwardToChat
                            ?
                            <div className={"private-forwarding-list-bottom"}>
                                <button
                                    className={`btn ${isChatSelected({"chat_id": "disput"}) ? "btn-primary" : "btn-secondary"}  ws-forwarding-contact-button`}
                                    onClick={() => toggleForwardingChat({"chat_id": "disput"})}
                                >
                                    {/*<img className="avatar-mini dont-drag" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>*/}
                                    Светский диспут {isChatSelected({"chat_id": "disput"}) ? "✓" : null}
                                </button>
                                {store.chatList.map(chat => {
                                    console.log(toJS(chat))
                                    return (
                                        <button
                                            key={chat._id}
                                            className={`btn ${isChatSelected(chat) ? "btn-primary" : "btn-secondary"}  ws-forwarding-contact-button`}
                                            onClick={() => toggleForwardingChat(chat)}
                                        >
                                            {/*<img className="avatar-mini dont-drag" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>*/}
                                            {chat.chatName} {isChatSelected(chat) ? "✓" : null}
                                        </button>
                                    );
                                })}
                            </div>
                            :
                            fullUserListToRoll
                        }
                    </div>
                </div>
            </div>



            <div className={"private-chat-top"} id={"private-chat-top"}>


                {drag
                    ?
                    <div
                        className={"drop-area"}
                        onDragStart={(e) => handleDragStart(e)}
                        onDragLeave={(e) => handleDragLeave(e)}
                        onDragOver={(e) => handleDragStart(e)}
                        onDrop={(e) => onDropHandler(e)}
                    >
                        <h3>Отпустите файл здесь</h3>
                        <img src={SvgUpload} width={"250"}/>
                    </div>
                    :
                    <div
                        className={"private-chat-messages-area-under message-area"}
                        ref={myRef}
                        onDragStart={(e) => handleDragStart(e)}
                        onDragLeave={(e) => handleDragLeave(e)}
                        onDragOver={(e) => handleDragStart(e)}
                    >
                        {showArrowDown && activeUser?.id
                            ?
                            <div className={"fixed-arrow-at-container"}>
                            <div
                                className={`fixed-arrow`}
                                onClick={() => {
                                    console.log("Давайте скроллить!")
                                    downArrowAction()

                                }}
                            >
                                <img className={"dont-drag"} src={SvgArrowDown} width={"35"}/>
                            </div></div>
                            :
                            null
                        }
                        <div
                            className={`${activeUser ? "private-chat-messages-area" : "private-chat-messages-area-empty" }`}
                            id={"sourceElement"}
                        >

                            {activeUser
                                ?
                                messagesToRoll
                                :
                                <div className={"margin-auto private-plug ubuntu"}>{userList.length ? "Не выбран ни один диалог" : 'Для начала диалога нажмите кнопку "Сообщение" в профиле пользователя'}</div>
                            }
                        </div>
                    </div>
                }


                {typersArray.length && containActiveId()
                    ?
                    <p className={"somebody-writing-private"}>
                        {typersArray.length > 2
                            ?
                            typersArray[0]?.name + ", " + typersArray[1]?.name + ", и ещё " + (typersArray.length - 2).toString() + " человек"
                            :
                            typersArray.length === 2
                                ?
                                typersArray[0]?.name + " и " + typersArray[1]?.name
                                :
                                typersArray[0]?.name
                        } {` пиш${typersArray.length > 1 ? "ут" : "ет" }`}{threeDots}🖊
                    </p>
                    :
                    <p className={"somebody-writing-private"}></p>
                }

                {isMessageEditing
                    ?
                    <div className={"ws-answering-message-wrapper margin-top-20"}>

                        <div className={"answering-message"}>
                            <h6 className={"margin-0"}>{`${showDelButton ? "Редактирование сообщения" : "Удаляем?" }`}</h6>
                        </div>
                        {showDelButton
                            ?
                            <button
                                className={"btn btn-secondary border-radius-8 inline margin-left-20"}
                                onClick={() => cancelEditing()}
                            >Отменить</button>
                            :
                            null
                        }

                        {showDelButton
                            ?
                            <button
                                className={"btn btn-danger border-radius-8 inline margin-left-20"}
                                onClick={() => setShowDelButton(false)}
                            >Удалить</button>
                            :
                            <div>
                                <button
                                    className={"btn btn-danger border-radius-8 inline margin-left-20"}
                                    onClick={() => deleteMessage()}
                                >Да</button>
                                <button
                                    className={"btn primary-btn-color inline margin-left-20"}
                                    onClick={() => setShowDelButton(true)}
                                >Нет</button>
                            </div>

                        }

                    </div>
                    :
                    null
                }


                {answeringMessage.text
                    ?
                    <h6 className={"margin-top-5 margin-left-10"}>В ответ на:</h6>
                    :
                    null
                }
                {answeringMessage.text
                    ?
                    <div className={"answering-message-wrapper margin-left-20"}>
                        <div className={"answering-message"}>
                            {processMessageText(textReducer(answeringMessage.text))}
                        </div>
                        <img
                            className={"red-cross-private-delete-attache"}
                            src={SvgRedCross} width={"25px"}
                            onClick={() => cancelAnswering()}
                            title={"Удалить вложение"}
                        />
                        {/*<button*/}
                        {/*    className={"btn inline margin-left-20 cancel-answering-button"}*/}
                        {/*    onClick={() => cancelAnswering()}*/}
                        {/*>❌</button>*/}
                    </div>

                    :
                    null
                }
                <div className={"private-chat-bottom"}>


                    {/*<div className={"private-compose-form"}>*/}
                    {/*<div className={"private-search-list-absolute-container"}>*/}
                        {messageSearching && searchMessageValue.length >=3 && showFoundedList
                            ?
                            <div className={"search-list-container"} id={"search-list-container"}>

                                {foundedMessages.length
                                    ?
                                    foundedMessages.map(message => {
                                        return <div
                                            className={"invocation-user-and-avatar-container"}
                                            onClick={() => {
                                                setShowFoundedList(false)
                                                goToInvocation(message._id)
                                            }}
                                        >
                                            <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${message.sender?._id}/avatar.png`}/>
                                            <div className={"search-date-and-name-container"}>
                                            <span className={"ws-chat-message-date"}>
                                                {new Date(message.date).toLocaleString([],{year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute:'2-digit'})}
                                            </span>
                                                <span className={"rem07 margin-right-10 bold"}>
                                                {message.sender?.name + " " + message.sender?.lastName + ":" }
                                            </span>
                                            </div>
                                            <div
                                                title={message.text}
                                                className={"answering-message"}>{highlightTextAroundPattern(message.text, searchMessageValue)}
                                            </div>


                                        </div>
                                    })
                                    :
                                    <div
                                        className={"invocation-user-and-avatar-container bold"}
                                        //onClick={() => setInvocationTo({to: "all"})}
                                    >Совпадений не найдено</div>
                                }



                            </div>
                            :
                            null
                        }
                    {/*</div>*/}

                    <div
                        className={"private-textarea-and-search-container"}
                        id={"targetElement"}
                    >



                        <textarea
                            ref={textAreaRef}
                            rows={1}
                            className={`${messageSearching ? "private-chat-text-input-search" : "private-chat-text-input" }`}
                            // className={`private-chat-text-input ${markedMessages.length && !forwardingList.length ? "width-60" : null}`}
                            placeholder={`${messageSearching ? "Введите запрос..." : "Напишите сообщение..."}`}
                            type={"text"}
                            id={"chat-input"}
                            value={
                                isMessageEditing
                                    ?
                                    editedText
                                    :
                                    messageSearching
                                        ?
                                        searchMessageValue
                                        :
                                        printedMessage
                            }
                            onPaste={(e) => {
                                if(!messageSearching) {
                                    handlePaste(e)
                                }
                            }}
                            onClick={() => {
                                if(searchMessageValue.length >= 3) {
                                    setShowFoundedList(true)
                                }
                            }}
                            onChange={(e) => {


                                if(!isMessageEditing) {

                                    if(messageSearching) {
                                        setSearchMessageValue(e.target.value)

                                    } else {
                                        if(printedMessage === "" && e.target.value === "\n") {
                                            ;
                                        } else {
                                            setPrintedMessage(e.target.value)
                                            //textAreaAdjust()
                                        }
                                    }

                                } else {
                                    if(editedText === "" && e.target.value === "\n") {
                                        ;
                                    } else {
                                        setEditedText(e.target.value)
                                        //textAreaAdjust()
                                    }
                                }

                            }}

                            onKeyUp={(key) => {
                                if ((key.code === "ShiftLeft" || key.code === "ShiftRight") && !messageSearching) {
                                    setShiftEnterPressed(false);

                                }
                            }}

                            // onKeyDown={async (key) => {
                            //
                            //
                            //     if(!messageSearching) {
                            //
                            //
                            //         if (key.shiftKey) {
                            //             setShiftEnterPressed(true);
                            //         }
                            //
                            //
                            //         console.log(shiftEnterPressed)
                            //         if (key.code === "Enter" && !shiftEnterPressed) {
                            //             console.log("CASE1")
                            //             key.preventDefault()
                            //             if(!isMessageEditing) {
                            //                 if(printedMessage.length > 0 && printedMessage !== "\n") {
                            //                     //этот превент позволяет добиться отмены добавления двойного переноса строки
                            //                     //при зажатом шифте, и оставить только один
                            //                     key.preventDefault()
                            //                 }
                            //             } else {
                            //                 if(editedText.length > 0 && editedText !== "\n") {
                            //
                            //                     const textarea = key.target;
                            //                     console.log(textarea.target)
                            //                     const start = textarea.selectionStart;
                            //                     const end = textarea.selectionEnd;
                            //
                            //                     const textBeforeCursor = editedText.substring(0, start);
                            //                     const textAfterCursor = editedText.substring(end);
                            //
                            //                     await setEditedText(textBeforeCursor + "\n" + textAfterCursor);
                            //                     // Восстановление положения курсора
                            //                     const newPosition = start + 1; // +1, так как добавили один символ
                            //                     textarea.setSelectionRange(newPosition, newPosition);
                            //
                            //                 }
                            //             }
                            //             console.log("ADJUSTING 6")
                            //             textAreaAdjust()
                            //
                            //
                            //         }
                            //
                            //         if(key.code === "Enter" && !key.shiftKey || key.code === "NumpadEnter") {
                            //
                            //             console.log("CASE2")
                            //
                            //             if(!isMessageEditing) {
                            //                 sendMessage()
                            //             } else {
                            //                 saveEditedMessage()
                            //             }
                            //
                            //         } else {
                            //             if(key.code !== "Enter" && key.code !== "NumpadEnter") {
                            //                 console.log("CASE3")
                            //                 if(!isMessageEditing) {
                            //                     if(!debounceTimeout) {
                            //                         setDebounceTimeout(true)
                            //                         setTimeout(() => {
                            //                             setDebounceTimeout(false)
                            //                         }, 1500)
                            //                         iAmTyping()
                            //                     }
                            //                 }
                            //                 console.log("ADJUSTING 7")
                            //                 //textAreaAdjust()
                            //             }
                            //         }
                            //
                            //     }
                            //
                            //
                            // }}
                            onKeyDown={async (key) => {
                                if (!messageSearching) {
                                    if (key.shiftKey) {
                                        setShiftEnterPressed(true);
                                    }

                                    if ((key.code === "Enter" && !shiftEnterPressed) || (key.code === "NumpadEnter" && !shiftEnterPressed)) {
                                        console.log("CASE1");
                                        key.preventDefault();

                                        if (!isMessageEditing) {
                                            console.log("CASE11");
                                            if (printedMessage.trim().length > 0) {
                                                // Этот превент позволяет добиться отмены добавления двойного переноса строки
                                                // при зажатом шифте и оставить только один
                                                key.preventDefault();
                                            }
                                        } else {
                                            console.log("CASE12");
                                            if (editedText.trim().length > 0) {
                                                const textarea = key.target;
                                                const start = textarea.selectionStart;
                                                const end = textarea.selectionEnd;

                                                const textBeforeCursor = editedText.substring(0, start);
                                                const textAfterCursor = editedText.substring(end);

                                                await setEditedText(textBeforeCursor + textAfterCursor);
                                                // Восстановление положения курсора
                                                const newPosition = start; // Не добавляем новую строку при редактировании
                                                textarea.setSelectionRange(newPosition, newPosition);
                                            }
                                        }

                                        console.log("ADJUSTING 6");
                                        textAreaAdjust();
                                    }

                                    if ((key.code === "Enter" && !key.shiftKey) || (key.code === "NumpadEnter" && !key.shiftKey)) {
                                        console.log("CASE2");

                                        if (!isMessageEditing) {
                                            if(!messageSending) {
                                                sendMessage()
                                            }

                                        } else {
                                            saveEditedMessage();
                                        }
                                    } else {
                                        if (key.code !== "Enter" && key.code !== "NumpadEnter") {
                                            console.log("CASE3");
                                            if (!isMessageEditing) {
                                                if (!debounceTimeout) {
                                                    setDebounceTimeout(true);
                                                    setTimeout(() => {
                                                        setDebounceTimeout(false);
                                                    }, 1500);
                                                    iAmTyping();
                                                }
                                            }
                                            console.log("ADJUSTING 7");
                                            // textAreaAdjust();
                                        }
                                    }
                                }
                            }}


                        />
                    </div>

                    {/*<div className={"margin-left-20"} id={"private-ballast"}></div>*/}
                    {markedMessages.length
                        ?
                        null
                        :
                        <div className={"private-screpka-and-send-container"}>
                            <input type={"file"}
                                   id={"private-chat-input-file"}
                                   name={"files[]"}
                                   multiple
                                   accept={"image/png, image/gif, image/jpeg, application/pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .rar, .zip"}
                                   onChange={(e) => {handleFiles(e.target.files)}}
                            />
                            {!answeringMessage.quote_sender_name
                                && !isMessageEditing
                                && !markedMessages.length
                                && !showFoundedList
                                && activeUserRef.current
                                ?
                                <img
                                    className={"dont-drag search-lens-messages pointer margin-right-5"}
                                    src={messageSearching ? SvrSearchLensRed : SvgSearchLens}
                                    alt="Поиск по истории сообщений"
                                    title="Поиск по истории сообщений"
                                    width={"30px"}
                                    onClick={() => {
                                        if(messageSearching) {
                                            setSearchUserValue("")
                                        }
                                        setMessageSearching(prev =>!prev)
                                        textAreaRef.current.focus()
                                    }}
                                />
                                :
                                null
                            }
                            {!messageSearching
                                ?
                                <label htmlFor={"private-chat-input-file"}>
                                <span className={"ws-input-file-icon-wrapper"}>
                                    <img className={"ws-input-file-icon dont-drag"} src={SvgPaperClip} width={"30px"}/>
                                    {/*<img className={"ws-input-file-icon dont-drag"} src={require("../img/skrepka.png")} width={"25"}/>*/}
                                </span>
                                </label>
                                :
                                null
                            }
                            {!messageSearching
                                ?
                                <img
                                    src={SvgSend}
                                    width={"30px"}
                                    className={"pointer"}
                                    onClick={() => {
                                        if(!isMessageEditing) {
                                            if(messageSearching) {
                                                stopMessageSearch()
                                            } else {
                                                if(!messageSending) {
                                                    sendMessage()
                                                }

                                            }
                                        } else {
                                            saveEditedMessage()
                                        }
                                    }}
                                />
                                :
                                null
                            }

                        {/*    <button*/}
                        {/*    className={"btn btn-warning margin-left-20"}*/}
                        {/*    onClick={() => {*/}
                        {/*        if(!isMessageEditing) {*/}
                        {/*            if(messageSearching) {*/}
                        {/*                stopMessageSearch()*/}
                        {/*            } else {*/}
                        {/*                if(!messageSending) {*/}
                        {/*                    sendMessage()*/}
                        {/*                }*/}

                        {/*            }*/}
                        {/*        } else {*/}
                        {/*            saveEditedMessage()*/}
                        {/*        }*/}
                        {/*    }}*/}

                        {/*>{!messageSearching*/}
                        {/*    ?*/}
                        {/*    "Отправить"*/}
                        {/*    :*/}
                        {/*    "Отмена"*/}
                        {/*}</button>*/}
                        </div>
                    }



                    {markedMessages.length
                        ?
                        <div className={"private-forward-and-close-buttons-container"}>
                            {(!document.getElementById('user-list').classList.contains('rotated-180') && (!forwardingList.length || !forwardingChatList.length))
                                || (document.getElementById('user-list').classList.contains('rotated-180') && (forwardingList.length || forwardingChatList.length))
                                ?
                                <img
                                    src={forwardingList.length || forwardingChatList.length  ? SvgSend : SvgForwardSend}
                                    width={forwardingList.length || forwardingChatList.length ? "30px" : "35px"}
                                    disabled={disableForward && !forwardingList.length && !forwardingChatList.length}
                                    className={"pointer"}
                                    onClick={() => {
                                        document.querySelector("#user-list").classList.add("rotated-180")
                                        if(!forwardingList.length || !forwardingChatList.length) {
                                            setDisableForward(true)
                                        }
                                        if(forwardingList.length || forwardingChatList.length) {
                                            if(!messageSending) {
                                                sendMessage()
                                                setDisableForward(false)
                                            }

                                        }
                                    }}
                                />
                                :
                                null
                            }

                            {/*<button*/}

                            {/*    disabled={disableForward && !forwardingList.length && !forwardingChatList.length}*/}
                            {/*    className={"btn btn-warning margin-left-20"}*/}
                            {/*    onClick={() => {*/}
                            {/*        document.querySelector("#user-list").classList.add("rotated-180")*/}
                            {/*        if(!forwardingList.length || !forwardingChatList.length) {*/}
                            {/*            setDisableForward(true)*/}
                            {/*        }*/}
                            {/*        if(forwardingList.length || forwardingChatList.length) {*/}
                            {/*            if(!messageSending) {*/}
                            {/*                sendMessage()*/}
                            {/*                setDisableForward(false)*/}
                            {/*            }*/}

                            {/*        }*/}
                            {/*    }}*/}
                            {/*>{forwardingList.length || forwardingChatList.length  ? "Отправить ➔" : "Переслать ➔" }</button>*/}
                            <img
                                src={SvgCancel}
                                className={"private-cancel-forwarding-btn"}
                                width={"20px"}
                                onClick={() => {
                                    setMarkedMessages(prev => [])
                                    setForwardingList(prev => [])
                                    setForwardingChatList(prev => [])
                                    setDisableForward(false)
                                    document.querySelector("#user-list").classList.remove("rotated-180")
                                }}
                            />
                            {/*<button*/}
                            {/*    className={"btn btn-warning margin-left-20"}*/}
                            {/*    onClick={() => {*/}
                            {/*        setMarkedMessages(prev => [])*/}
                            {/*        setForwardingList(prev => [])*/}
                            {/*        setForwardingChatList(prev => [])*/}
                            {/*        setDisableForward(false)*/}
                            {/*        document.querySelector("#user-list").classList.remove("rotated-180")*/}
                            {/*    }}*/}
                            {/*>❌</button>*/}
                        </div>
                        :
                        null
                    }
                    {/*{activeUser?.id*/}
                    {/*    ?*/}
                    {/*    <img*/}
                    {/*        className={"dont-drag opened-private-dialog-avatar"}*/}
                    {/*        src={`${API_URL}/public/avatars/${activeUser.id}/avatar.png`}*/}

                    {/*    />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                </div>
                {!messageSearching
                    ?
                    <p className={"hints-on-blue"}>Новая строка Shift+Enter</p>
                    :
                    <p className={"hints-on-blue"}>Я не ищу, я нахожу!</p>
                }
                <div className={"red"}>{errorMessage.show ? errorMessage.text : null}</div>
                {spinner
                    ?
                    <div>
                        <p>Прикрепляем файлы...</p>
                        <img src={require('../img/loading.gif')} width={"35px"} className={"margin-left-20 dont-drag"}/>
                    </div>
                    :
                    null
                }

                {pinnedFiles.map((file, index) => {

                    let type

                    if(file.type === "doc" || file.type === "docx") {
                        type = "word"
                    } else if (file.type === "xls" || file.type === "xlsx") {
                        type = "excel"
                    } else if (file.type === "ppt" || file.type === "pptx") {
                        type = "power_point"
                    } else if (file.type === "rar" || file.type === "zip") {
                        type = "winrar"
                    } else if (file.type === "pdf") {
                        type = "pdf"
                    } else {
                        type = "doc_thumb"
                    }
                    console.log(file.type)
                    const filenameWindows = file.path.substring(file.path.lastIndexOf("\\") + 1).replace(/_/g, " ");
                    let filename
                    if (file.type !== "image" && file.type !== undefined) {
                        //filename = file.path.split('\\').pop().replace(/_/g, " ")
                        filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                    }
                    console.log(file)
                    return (
                        <div key={index + Math.random()} className={"margin-top-20 private-attached-file-container"}>
                            <img
                                className={"red-cross-private-delete-attache"}
                                src={SvgRedCross} width={"25px"}
                                onClick={() => deleteAttached(file.path, file.type)}
                                title={"Удалить вложение"}
                            />
                            {file.type === "image"
                                ?
                                <img className={"chat-pre-image dont-drag"} src={API_URL + "/" + file.thumbnail}/>
                                :
                                file.type !== undefined
                                    ?
                                    <div>
                                        <img className={"order-done-file-image dont-drag"} src={require(`../img/${type}.png`)} width={"30"}/>
                                        <div className={"inline"} key={index}>{filename.length > 70 ? filename.slice(0, 50) + " ... " + filename.slice(-10) : filename}</div>
                                    </div>
                                    :
                                    null
                            }


                            {/*<button*/}
                            {/*    className={"btn btn-outline-danger inline margin-left-20"}*/}
                            {/*    onClick={() => deleteAttached(file.path, file.type)}*/}
                            {/*>❌</button>*/}
                        </div>
                    )})}



            </div>







            {/*<NestedMessageModal*/}
            {/*    active={activeMessageModal}*/}
            {/*    setActive={setActiveMessageModal}*/}
            {/*    message={nestedContent}*/}
            {/*    dateSet={dateSet}*/}
            {/*    // renderForwardedMessages={renderForwardedMessages}*/}
            {/*    showNestedMessage={showNestedMessage}*/}
            {/*    linkRoller={linkRoller}*/}
            {/*    processMessageText={processMessageText}*/}
            {/*    lastSteps={lastSteps}*/}
            {/*    setLastSteps={setLastSteps}*/}
            {/*    stepCounter={stepCounter}*/}
            {/*    setStepCounter={setStepCounter}*/}
            {/*    action={goToQuotedMessage}*/}
            {/*    chatId={"privateMessages"}*/}
            {/*/>*/}
            <ModalDialog
                active={activeFileDoesntExist}
                setActive={setActiveFileDoesntExist}
                action={() => {;}}
                // redMessage={`Вы считаете что по заказу #`}
                message={`Этот файл больше не существует`}
                purpose={"info"}
            />
            <ModalImgViewer
                active={activeModalImgViewer}
                setActive={setActiveModalImgViewer}
                action={() => {;}}
                imgSrc={imageSource}
                setImgSrc={setImageSource}
            />
            {/*</div>*/}
        </div>
    );
};

export default observer(PrivateMessages);