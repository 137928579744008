import React, {useEffect, useRef, useState} from 'react';
import {throttle} from "./repeat_functions";
import $api, {API_URL} from "../../http";
import {Link} from "react-router-dom";
import Loading from "../Loading";

const PaymentViewer = ({userId}) => {



    const fetchLimit = 100
    const [paymentKeeper, setPaymentKeeper] = useState([])
    const [paymentFetching, setPaymentFetching] = useState(true)
    const [skipCounter, setSkipCounter] = useState(0)
    const [stopFetching, setStopFetching] = useState(false)
    const [filter, setFilter] = useState({destination: "all"})

    const [userSearchString, setUserSearchString] = useState("")
    const userSearchTimer = useRef()
    const [nowUserSearching, setNowUserSearching] = useState(false)
    const [showEmptySearch, setShowEmptySearch] = useState(false)
    const [foundedUsers, setFoundedUsers] = useState([])


    async function getSearchingUsers(value) {
        setNowUserSearching(true)
        const response = await $api.get(`/search_users?value=${value}`)
        console.log(response.data)
        setFoundedUsers(response.data)
        if(!response.data.length) setShowEmptySearch(true)
        setNowUserSearching(false)
        userSearchTimer.current = null
    }



    useEffect(() => {

        clearTimeout(userSearchTimer.current)
        setShowEmptySearch(false)
        if(userSearchString.length > 2) {

            userSearchTimer.current = setTimeout(() => {getSearchingUsers(userSearchString)}, 1000)

        } else {
            userSearchTimer.current = null
            setFoundedUsers([])
        }
    }, [userSearchString])


    function handleScroll(e) {

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            console.log("Пора фетчить")
            setPaymentFetching(true)
        }

        console.log('Общая высота', e.target.documentElement.scrollHeight)
        console.log('Текущее положение', e.target.documentElement.scrollTop)
        console.log('Высота видимой области', window.innerHeight)

    }

    const scrollHandler = throttle(handleScroll, 600)


    useEffect(() => {

        //слушатель события "scroll" повешенный на весь документ
        document.addEventListener('scroll', scrollHandler)

        //при размонтировании элемента эта функция так же отключает слушатель события
        return function() {
            console.log("DO REMOVE")
            document.removeEventListener('scroll', scrollHandler)
        }

    }, [])


    async function getPhysicalPayments(skipCounter, filter) {
        console.log(filter)
        const response = await $api.get(`/get_physical_payments?skip=${skipCounter}&limit=${fetchLimit}&filter=${filter}`)
        console.log(response.data)
        setPaymentKeeper(prev => [...prev, ...response.data])
        setPaymentFetching(false)
        setSkipCounter(prev => prev + fetchLimit)
        if(response.data.length < fetchLimit) setStopFetching(true)
    }

    async function getPayments(skipCounter, filter) {
        console.log(filter)
        const response = await $api.get(`/get_payments_admin?skip=${skipCounter}&limit=${fetchLimit}&filter=${JSON.stringify(filter)}&userId=${userId}`)
        console.log(response.data)

        setPaymentKeeper(prev => [...prev, ...response.data])
        setPaymentFetching(false)
        setSkipCounter(prev => prev + fetchLimit)
        if(response.data.length < fetchLimit) setStopFetching(true)
    }


    useEffect(() => {
        console.log('filter changed')
        if(paymentFetching && !stopFetching) {
            console.log("payment fetching")
            getPayments(skipCounter, filter)
        } else {
            console.log("NO fetching")
            console.log(paymentFetching)
            console.log(!stopFetching)
        }
    }, [paymentFetching, skipCounter, stopFetching, filter])


    return (
        <div>
            <div className={"payment-viewer-filter-container"}>
                <select
                    onChange={(e) => setFilter(prev => {
                        const newFilter = {...prev}
                        newFilter.destination = e.target.value
                        console.log(newFilter)
                        setSkipCounter(0)
                        setPaymentKeeper([])
                        setStopFetching(false)
                        setPaymentFetching(true)
                        return newFilter
                    })}
                    value={filter.destination}
                >
                    <option value={'all'}>Все операции</option>
                    <option value={'regular'}>Поступления</option>
                    <option value={'debit'}>Списания</option>
                </select>

                <div className={"payment-filter-user-list"}>
                    <input
                        id={"private-order-user-input"}
                        className={"payment-filter-user-input"}
                        placeholder={"Начните писать имя..."}
                        value={userSearchString}
                        onChange={(e) => {
                            setUserSearchString(e.target.value)
                        }}
                    />
                    {nowUserSearching
                        ?
                        <img src={require('../../img/loading.gif')} width={"35px"} className={"dont-drag"}/>
                        :
                        null
                    }
                    {foundedUsers.length
                        ?
                        <div className={"founded-user-list"} id={"founded-user-list"}>
                            {/*<h5>Заглушка</h5>*/}
                            {foundedUsers.map(user => {
                                return (<div
                                    className={"invocation-user-and-avatar-container"}
                                    key={user.id}
                                    onClick={() => {
                                        setFilter(prev => {
                                            const newFilter = { ...prev }
                                            newFilter.payerId = user.id
                                            setPaymentKeeper([])
                                            setStopFetching(false)
                                            setPaymentFetching(true)
                                            setFoundedUsers([])
                                            setUserSearchString("")
                                            return newFilter
                                        })
                                        // setPotentialDoer({_id: user.id, title: user.name + " " + user.lastName})
                                        // setFoundedUsers([])
                                        // setUserSearchString("")
                                        // setErrorOfPlacing(false)
                                    }}
                                >
                                    <img className={"ws-message-avatar dont-drag"}
                                         src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                    <p
                                        className={"invocation-user margin-0"}
                                    >{user.name + " " + user.lastName}</p></div>)

                            })}
                        </div>
                        :
                        userSearchString.length > 2 && showEmptySearch
                            ?
                            <div className={"founded-user-list"} id={"founded-user-list"}>
                                <h6 className={"invocation-user margin-0"}
                                >Нет совпадений</h6>
                            </div>
                            :
                            null
                    }
                </div>
                <button
                    className={"btn btn-danger payment-filter-btn"}
                    onClick={() => {
                        setPaymentKeeper([])
                        setStopFetching(false)
                        setPaymentFetching(true)
                        setFoundedUsers([])
                        setUserSearchString("")
                        setFilter({destination: "all"})
                    }}
                >Сброс</button>
            </div>

            <table className="table invite-table">
                <thead>
                <tr>
                    <th className={"order-table-th"} scope="col">#</th>
                    <th className={"order-table-th"} scope="col">Дата</th>
                    <th className={"order-table-th"} scope="col">Плательщик</th>
                    <th className={"order-table-th"} scope="col">Сумма</th>
                    <th className={"order-table-th"} scope="col">К зачислению</th>
                    <th className={"order-table-th"} scope="col">Описание</th>
                    <th className={"order-table-th"} scope="col">Статус</th>

                </tr>
                </thead>
                <tbody>

                {paymentKeeper.map((payment, index) => {

                    return (
                        <tr key={payment._id}>
                            <td className={"order-table-td"}>{index + 1}</td>
                            <td title={payment.date} className={"order-table-td"}>
                                {new Date(payment.date).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    timeZone: 'Europe/Moscow'
                                })}
                            </td>
                            <td className={"order-table-td"}>
                                <div>
                                    <img className={"ws-message-avatar dont-drag"}
                                         src={`${API_URL}/public/avatars/${payment.user_id?._id}/avatar.png`}/>
                                    <Link target={"_blank"} to={`/user-details/${payment.user_id?._id}`}>
                                        {payment.user_id?.name + " " + payment.user_id?.lastName}
                                    </Link>
                                </div>


                            </td>
                            <td className={"order-table-td"}>
                                {payment.amount}
                            </td>
                            <td className={"order-table-td"}>{payment?.income}</td>
                            <td className={"order-table-td"}>{payment?.description}</td>

                            {payment?.status === "succeeded"
                                ?
                                <td className={"order-table-td green-color"}>Проведён</td>
                                :
                                payment?.status === "canceled"
                                    ?
                                    <td className={"order-table-td red-for-gray"}>Отменён</td>
                                    :
                                    payment.failed
                                        ?
                                        <td className={"order-table-td"}>Ошибка</td>
                                        :
                                        <td className={"order-table-td"}>В обработке</td>
                            }


                        </tr>
                    )
                })}

                </tbody>
            </table>
            {paymentFetching && !stopFetching
                ?
                <Loading/>
                :
                null
            }
        </div>
    );
};

export default PaymentViewer;